import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { MATERIAL_CACHE_PATH } from '../../../../constants/globalConstants'
import { switchFavouriteRequest } from '../../../../utils/sharedApi'
import {
  MaterialPreviewType,
  MaterialsResponseType,
} from '../../../../utils/types'
import { getFavouriteMaterials } from './GalleryModule-api'

export const useFetchFavouriteMaterials = (
  page: number = 1,
  category: string | undefined,
  filterDate: string | null,
  sortOrder: string | undefined,
  filterAuthor: string | null
) => {
  const query = useQuery(
    [
      MATERIAL_CACHE_PATH,
      {
        type: 'favourite',
        page,
        category,
        filterDate,
        sortOrder,
        filterAuthor,
      },
    ],
    async () =>
      await getFavouriteMaterials(
        page,
        category,
        filterDate,
        sortOrder,
        filterAuthor
      ),
    {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
    }
  )
  return query
}

export const useFavouriteItem = (queryKey: any[]) => {
  const client = useQueryClient()
  const mutation = useMutation((id: number) => switchFavouriteRequest(id), {
    onMutate: async (id) => {
      const oldMaterialsInfo: MaterialsResponseType | undefined =
        await client.getQueryData(queryKey)

      if (oldMaterialsInfo === undefined) {
        return
      }

      const updatedMaterials = {
        items: oldMaterialsInfo.items.filter(
          (item: MaterialPreviewType) => item.id !== id
        ),
        total: oldMaterialsInfo.total,
        page: oldMaterialsInfo.page,
        size: oldMaterialsInfo.size,
      }
      client.setQueryData(queryKey, updatedMaterials)
      return { oldMaterialsInfo }
    },
    onError: (err, newTodo, context: any) => {
      client.setQueryData(queryKey, context.oldMaterialsInfo)
      toast.error('Не удалось удалить из избранного')
    },
    onSettled: async () => {
      // await client.invalidateQueries(MATERIAL_CACHE_PATH)
    },
  })
  return mutation
}
