import React, { useMemo } from 'react'
import { FilterModule } from '../../modules/FilterModule'
import { SearchModule } from '../../modules/SearchModule'
import { CategoriesModule } from '../../modules/CategoriesModule'
import { GalleryModule } from './modules/GalleryModule'
import { useCategories, useFetchContributorList } from '../../utils/dataHooks'
import { OptionType } from '../../utils/types'
import { useLocation } from 'react-router'

export const SearchPage = () => {
  const { search } = useLocation()
  const searchParams = useMemo(
    () => Object.fromEntries(new URLSearchParams(search)),
    [search]
  )

  const { data: categoriesList } = useCategories() as {
    data: OptionType[]
    isLoading: boolean
  }
  const { data: contributorsList } = useFetchContributorList() as {
    data: OptionType[]
  }
  const {
    search: searchStr,
    tags,
    categories: category,
    contributorId,
  } = searchParams

  const [title, setTitle] = React.useState(searchStr)

  React.useEffect(() => {
    if (searchStr) {
      setTitle(`Найденное по запросу «${searchStr}»`)
    } else if (category) {
      if (categoriesList?.length) {
        const categoryTitle = categoriesList.find(
          (categoryFromList) => categoryFromList.id === +category
        )?.label
        setTitle(`Найденное в категории «${categoryTitle}»`)
      }
    } else if (contributorId) {
      if (contributorsList?.length) {
        const authorTitle = contributorsList.find(
          (contributor) => contributor.value === contributorId
        )?.label
        setTitle(`Найденное у автора «${authorTitle}»`)
      }
    } else if (tags) {
      setTitle(`Найденное по тегу «${tags}»`)
    } else {
      setTitle('')
    }
  }, [
    searchStr,
    search,
    tags,
    category,
    categoriesList,
    contributorsList,
    contributorId,
  ])

  return (
    <div className='section-search bottom-indent'>
      <CategoriesModule />
      <div className='section-outer bottom-indent'>
        <div className='section-inner materials-page'>
          <SearchModule className='materials-page__search' />
          <FilterModule
            className='materials-page__filters'
            title={title}
            withAuthor
            withCategory
            withDate
            withSort
          />
          <GalleryModule className='materials-page__gallery' />
        </div>
      </div>
    </div>
  )
}
