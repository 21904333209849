import React from 'react'
import { FilterModule } from '../../modules/FilterModule'
import { SearchModule } from '../../modules/SearchModule'
import { GalleryModule } from './modules/GalleryModule'

export const FavouritePage = () => {
  return (
    <div className='section-outer section-favourite bottom-indent'>
      <div className='section-inner materials-page'>
        <SearchModule className='materials-page__search' />
        <FilterModule
          className='materials-page__filters'
          withAuthor
          withCategory
          withDate
          withSort
        />
        <GalleryModule className='materials-page__gallery' />
      </div>
    </div>
  )
}
