import React from 'react'
import { SkeletonBase } from '../SkeletonBase'
import { SkeletonGallery } from '../SkeletonGallery'
import './SkeletonMainGallery.sass'

export const SkeletonMainGallery = () => {
  return (
    <div className='skeleton-main-gallery'>
      <SkeletonBase type='text' className='skeleton-main-gallery__title' />
      <SkeletonGallery itemsCount={12} />
    </div>
  )
}
