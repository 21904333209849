import classNames from 'classnames'
import React from 'react'
import { SkeletonBase } from '../SkeletonBase'
import './SkeletonFilters.sass'

interface SkeletonFiltersProps {
  className?: string
}

export const SkeletonFilters: React.FC<SkeletonFiltersProps> = ({
  className,
}) => {
  const filterPrfx = 'skeleton-filters'
  const filterCls = classNames(filterPrfx, {
    [`${className}`]: className,
  })
  return (
    <div className={filterCls}>
      <SkeletonBase type='text' className={`${filterPrfx}__title`} />
      <div className={`${filterPrfx}-actions`}>
        <SkeletonBase type='text' className={`${filterPrfx}-actions__item`} />
        <SkeletonBase type='text' className={`${filterPrfx}-actions__item`} />
      </div>
    </div>
  )
}
