import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import {
  SELF_CACHE_PATH,
  SELF_STATISTICS_CACHE_PATH,
} from '../../constants/globalConstants'
import { getSelfStatistic, getSelfUser } from './UserProfile-api'

export const useFetchProfileInfo = (isAuth: boolean) => {
  const query = useQuery(SELF_CACHE_PATH, async () => await getSelfUser(), {
    onError: (error: any) => {
      toast.error(error ?? 'Не удалось получить все данные')
    },
    retry: 1,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    enabled: isAuth === true,
  })
  return query
}

export const useFetchProfileStatistic = (isAuth: boolean) => {
  const query = useQuery(
    SELF_STATISTICS_CACHE_PATH,
    async () => await getSelfStatistic(),
    {
      onError: (error: any) => {
        toast.error(error ?? 'Не удалось получить вашу статистику')
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      enabled: isAuth === true,
    }
  )
  return query
}
