import React from 'react'
import './ContactsPage.sass'

export const ContactsPage = () => {
  return (
    <div className='section-outer section-contacts bottom-indent'>
      <div className='section-inner contacts-page'>
        <h1 className='contacts-page__title'>Контакты</h1>
        <div className='contacts-page__text'>
          По вопросам приобретения подписки на фотобанк можно обращаться по
          адресу:{' '}
          <a
            className={'contacts-page__text-link'}
            href={`mailto:julia@media4media.ru`}
            rel='noreferrer'
            target={'_blank'}
          >
            julia@media4media.ru
          </a>
        </div>
      </div>
    </div>
  )
}
