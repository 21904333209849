import React from 'react'
import { NavLink } from 'react-router-dom'
import { Button } from '../../components/Button'
import { ReactComponent as Logo } from '../../assets/img/logo.svg'
import './Header.sass'
import { useSelector } from 'react-redux'
import { UserIcon } from '../../components/UserIcon'
import {
  ABOUT_PATH,
  AUTHORS_PATH,
  CONTACTS_PATH,
  INFORMATION_PATH,
  MAIN_PATH,
} from '../../constants/globalConstants'
import { RootState } from '../../reducers'
import { ROLE_CONSUMER } from '../../constants/roles'
import { useAuthModal } from '../../hooks/useAuth'

export const Header = () => {
  const isAuth = useSelector((state: RootState) => state.auth.isAuth)
  const role = useSelector((state: RootState) => state.auth.permissions)
  const isAppUser = isAuth && role === ROLE_CONSUMER
  const handleOpenSignInModal = useAuthModal()

  const linkCls = 'header-menu__item pb-link'
  const linkActiveCls = 'header-menu__item pb-link pb-link_active'

  const getButton = () => {
    if (isAppUser) {
      return <UserIcon />
    } else {
      return (
        <Button
          className='header__btn'
          type='primary'
          short
          onClick={handleOpenSignInModal}
        >
          Войти
        </Button>
      )
    }
  }

  return (
    <header className='section-outer section-header'>
      <div className='section-inner header'>
        <div className='header'>
          <div className='header__logo'>
            <NavLink to={MAIN_PATH}>
              <Logo className='header__img' />
            </NavLink>
          </div>
          <div className='header-nav-group'>
            <ul className='header-menu'>
              {isAuth && (
                <NavLink
                  to={AUTHORS_PATH}
                  className={(props) => {
                    return props.isActive ? linkActiveCls : linkCls
                  }}
                >
                  Авторы
                </NavLink>
              )}

              <NavLink
                to={ABOUT_PATH}
                className={(props) => {
                  return props.isActive ? linkActiveCls : linkCls
                }}
              >
                О нас
              </NavLink>

              <NavLink
                to={CONTACTS_PATH}
                className={(props) => {
                  return props.isActive ? linkActiveCls : linkCls
                }}
              >
                Контакты
              </NavLink>

              <NavLink
                to={INFORMATION_PATH}
                className={(props) => {
                  return props.isActive ? linkActiveCls : linkCls
                }}
              >
                Информация
              </NavLink>
            </ul>
            {getButton()}
          </div>
        </div>
      </div>
    </header>
  )
}
