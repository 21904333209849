import { CATEGORY_POPULAR_API_PATH } from '../../constants/globalConstants'
import { instance } from '../../utils/coreAPI'
import { CategoryType } from '../../utils/types'
import { POPULAR_CATEGORIES_COUNT } from './CategoriesModule-constants'

export const getPopularCategories = async () => {
  try {
    const response = await instance.get(CATEGORY_POPULAR_API_PATH, {
      params: {
        count: POPULAR_CATEGORIES_COUNT,
      },
    })
    if (response.data?.length) {
      const categoriesOptions = response.data.map((category: CategoryType) => ({
        id: category.id,
        label: category.category,
        value: category.id.toString(),
      }))
      return categoriesOptions
    } else {
      return []
    }
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
