import React from 'react'
import './EmptyResult.sass'

export const EmptyResult = () => {
  return (
    <div className='empty-result'>
      <h2 className='empty-result__title'>
        По вашему запросу ничего не нашлось
      </h2>
    </div>
  )
}
