import classNames from 'classnames'
import React from 'react'
import './MaterialDetailItem.sass'

interface MaterialDetailItemProps {
  className?: string
  data: string
}

export const MaterialDetailItem: React.FC<MaterialDetailItemProps> = ({
  className,
  data,
}) => {
  const detailPrfx = 'material-details-item'
  const detailCls = classNames(detailPrfx, {
    [`${className}`]: className,
  })

  return (
    <div className={`${detailCls}`}>
      <span className={`${detailPrfx}__text`}>{data}</span>
    </div>
  )
}
