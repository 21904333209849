import classNames from 'classnames'
import React from 'react'
import './MaterialDetailDescription.sass'

interface MaterialDetailDescriptionProps {
  className?: string
  description: string
}

export const MaterialDetailDescription: React.FC<MaterialDetailDescriptionProps> =
  ({ className, description }) => {
    const detailPrfx = 'material-details-description'
    const detailCls = classNames(detailPrfx, {
      [`${className}`]: className,
    })

    return (
      <div className={`${detailCls}`}>
        <span className={`${detailPrfx}__text`}>{description}</span>
      </div>
    )
  }
