import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import {
  MATERIAL_CACHE_PATH,
  MATERIAL_NEWS_CACHE_PATH,
} from '../../../../constants/globalConstants'
import { switchFavouriteRequest } from '../../../../utils/sharedApi'
import { getNews } from './NewsModule-api'
import { NewsResponseType } from './NewsModule-types'

export const useFetchNews = (isAuth: boolean) => {
  const query = useQuery(
    [...MATERIAL_NEWS_CACHE_PATH, { isAuth }],
    async () => await getNews(),
    {
      keepPreviousData: true,
      staleTime: 600000,
      refetchInterval: 600000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  )
  return query
}

export const useFavouriteNewsItem = (isAuth: boolean) => {
  const client = useQueryClient()
  const mainGalleryKey = [...MATERIAL_NEWS_CACHE_PATH, { isAuth }]
  const mutation = useMutation((id: number) => switchFavouriteRequest(id), {
    onMutate: async (id) => {
      const oldNewsInfo: NewsResponseType | undefined =
        await client.getQueryData(mainGalleryKey)

      if (oldNewsInfo === undefined) {
        return
      }

      const updatedNews: NewsResponseType = {
        materials: oldNewsInfo.materials.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              isFavorite: !item.isFavorite,
            }
          } else {
            return item
          }
        }),
        news: oldNewsInfo.news,
      }
      client.setQueryData(mainGalleryKey, updatedNews)
      return { oldNewsInfo }
    },
    onError: (err, newTodo, context: any) => {
      client.setQueryData(mainGalleryKey, context.oldMaterialsInfo)
      toast.error('Не удалось добавить в избранное')
    },
    onSettled: async () => {
      await client.invalidateQueries([MATERIAL_CACHE_PATH, { type: 'main' }])
    },
  })
  return mutation
}
