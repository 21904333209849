import classNames from 'classnames'
import React from 'react'
import { ReactComponent as FilterImg } from '../../assets/img/filterIcon.svg'
import './FilterIcon.sass'

interface FilterIconProps {
  className?: string
  isActive: boolean
  onClick?: () => void
}

export const FilterIcon: React.FC<FilterIconProps> = ({
  className,
  isActive,
  onClick,
}) => {
  const filterPrfx = 'filter-icon'
  const filterCls = classNames(filterPrfx, {
    [`${filterPrfx}_active`]: isActive === true,
    [`${className}`]: className,
  })
  return (
    <div className={filterCls} onClick={onClick}>
      <span className={`${filterPrfx}__title`}>Фильтры</span>
      <FilterImg className={`${filterPrfx}__img`} />
    </div>
  )
}
