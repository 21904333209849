import React from 'react'
import { SkeletonBase } from '../SkeletonBase'
import { SkeletonMaterialDetail } from '../SkeletonMaterialDetail'
import './SkeletonDetailsPage.sass'

export const SkeletonDetailsPage = () => {
  return (
    <div className='skeleton-details-page'>
      <SkeletonBase type='text' className='skeleton-details-page__search' />
      <div className='skeleton-details-page-info'>
        <SkeletonBase
          type='thumbnail'
          className='skeleton-details-page-info-img'
        />
        <div className='skeleton-details-page-info-download'>
          <SkeletonBase
            type='text'
            className='skeleton-details-page-info-download__btn'
          />
        </div>
        <div className='skeleton-details-page-info-sub'>
          <SkeletonMaterialDetail className='skeleton-details-page-info-sub__text' />
          <SkeletonMaterialDetail className='skeleton-details-page-info-sub__text' />
          <SkeletonMaterialDetail className='skeleton-details-page-info-sub__text' />
          <SkeletonMaterialDetail className='skeleton-details-page-info-sub__text' />
          <SkeletonMaterialDetail className='skeleton-details-page-info-sub__text' />
          <SkeletonMaterialDetail className='skeleton-details-page-info-sub__text' />
          <SkeletonMaterialDetail className='skeleton-details-page-info-sub__text' />
        </div>
      </div>
    </div>
  )
}
