import classNames from 'classnames'
import React from 'react'
import { AiOutlineCloudDownload } from 'react-icons/ai'
import { FaEye } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { SEARCH_MATERIALS_PATH } from '../../constants/globalConstants'
import { getProtectedPathFromHash, getReducedValue } from '../../utils/utils'
import { MaterialImg } from '../MaterialImg'
import './AuthorCard.sass'

interface AuthorCardProps {
  className?: string
  id: number
  protectedHash: string
  name: string
  viewCount: number
  downloadCount: number
}

export const AuthorCard: React.FC<AuthorCardProps> = ({
  className,
  id,
  protectedHash,
  name,
  viewCount,
  downloadCount,
}) => {
  const cardPrfx = 'author-card'
  const cardCls = classNames(cardPrfx, {
    [`${className}`]: className,
  })
  const imgPath = getProtectedPathFromHash(protectedHash)
  const viewValue = getReducedValue(viewCount)
  const downloadValue = getReducedValue(downloadCount)
  return (
    <Link
      to={`${SEARCH_MATERIALS_PATH}?contributorId=${id}`}
      className={cardCls}
    >
      <div className={`${cardPrfx}__item ${cardPrfx}__name`}>{name}</div>
      <div className={`${cardPrfx}__item ${cardPrfx}__viewed`}>
        <span className={`${cardPrfx}__item-value`}>{viewValue}</span>
        <span className={`${cardPrfx}__item-title`}>
          <FaEye />
        </span>
      </div>
      <div className={`${cardPrfx}__item ${cardPrfx}__downloads`}>
        <span className={`${cardPrfx}__item-value`}>{downloadValue}</span>
        <span className={`${cardPrfx}__item-title`}>
          <AiOutlineCloudDownload />
        </span>
      </div>
      <MaterialImg srcPath={imgPath} className={`${cardPrfx}__img`} />
    </Link>
  )
}
