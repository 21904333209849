import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router'
import { FORBIDDEN_PATH } from '../../constants/globalConstants'
import { isUser } from '../../utils/auth'

export const RequireAuth = () => {
  const location = useLocation()
  if (!isUser()) {
    return <Navigate to={FORBIDDEN_PATH} state={{ from: location }} />
  }
  return <Outlet />
}
