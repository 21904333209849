import React from 'react'
import { Link } from 'react-router-dom'
import { CONTACTS_PATH } from '../../constants/globalConstants'
import './AboutPage.sass'

export const AboutPage = () => {
  return (
    <div className='section-outer section-about bottom-indent'>
      <div className='section-inner about-page'>
        <h1 className='about-page__title'>О проекте</h1>
        <div className='about-page__text'>
          Фотобанк MEDIA4MEDIA – это база уникальных фотографий, созданная
          специально для редакционного использования. <br />
          Наши тарифы доступны даже для небольших региональных изданий.
          <br />
          Стоимость подписки стартует от{' '}
          <span className={'about-page__text-medium'}>3 900 руб.</span> в месяц.
          <br /> Узнать более подробную информацию и отправить заявку на
          подключение к фотобанку можно в разделе{' '}
          <Link className={`about-page__text-link`} to={CONTACTS_PATH}>
            «Контакты»
          </Link>
          .
        </div>
      </div>
    </div>
  )
}
