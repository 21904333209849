export const CONTRIBUTOR_URL_VALUE = 'contributorId'
export const CATEGORY_URL_VALUE = 'category'
export const CONSUMER_USER_URL_TYPE = 'guest_only'
export const INTERVAL_URL_VALUE = 'interval'
export const NAME_URL_VALUE = 'name'
export const MATERIAL_STATUS_URL_VALUE = 'material_status'
export const ORDER_STATUS_URL_VALUE = 'order_status'
export const PAGE_URL_VALUE = 'page'
export const SORT_URL_VALUE = 'sort'
export const USER_TYPE_URL_VALUE = 'type'
export const USER_STATUS_URL_VALUE = 'user_status'
export const PURCHASE_INTERVAL_URL_VALUE = 'purchase_interval'
export const FAVORITE_INTERVAL_URL_VALUE = 'favorite_interval'
export const SEARCH_URL_VALUE = 'search'
export const TAGS_URL_VALUE = 'tags'
