import React from 'react'
import { SkeletonBase } from '../SkeletonBase'
import './SkeletonNews.sass'

export const SkeletonNews = () => {
  return (
    <div className='gallery-grid skeleton-news'>
      <div className='skeleton-news-today'>
        <SkeletonBase type='text' className='skeleton-news-today__title' />
        <div className='skeleton-news-today-news'>
          <SkeletonBase
            type='text'
            className='skeleton-news-today-news__text'
          />
          <SkeletonBase
            type='text'
            className='skeleton-news-today-news__text'
          />
        </div>
        <div className='skeleton-news-today-news'>
          <SkeletonBase
            type='text'
            className='skeleton-news-today-news__text'
          />
          <SkeletonBase
            type='text'
            className='skeleton-news-today-news__text'
          />
        </div>
        <div className='skeleton-news-today-news'>
          <SkeletonBase
            type='text'
            className='skeleton-news-today-news__text'
          />
          <SkeletonBase
            type='text'
            className='skeleton-news-today-news__text'
          />
        </div>
        <div className='skeleton-news-today-news'>
          <SkeletonBase
            type='text'
            className='skeleton-news-today-news__text'
          />
          <SkeletonBase
            type='text'
            className='skeleton-news-today-news__text'
          />
        </div>
        <div className='skeleton-news-today-news'>
          <SkeletonBase
            type='text'
            className='skeleton-news-today-news__text'
          />
          <SkeletonBase
            type='text'
            className='skeleton-news-today-news__text'
          />
        </div>
      </div>
      <SkeletonBase type='thumbnail' className='skeleton-news__item' />
      <SkeletonBase type='thumbnail' className='skeleton-news__item' />
      <SkeletonBase type='thumbnail' className='skeleton-news__item' />
      <SkeletonBase type='thumbnail' className='skeleton-news__item' />
      <SkeletonBase type='thumbnail' className='skeleton-news__item' />
      <SkeletonBase type='thumbnail' className='skeleton-news__item' />
      <SkeletonBase type='thumbnail' className='skeleton-news__item' />
      <SkeletonBase type='thumbnail' className='skeleton-news__item' />
    </div>
  )
}
