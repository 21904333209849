import { format, parseISO } from 'date-fns'
import { ru } from 'date-fns/locale'
import {
  BANK_PHOTO_PATH,
  BANK_REPORTS_PATH,
} from '../constants/globalConstants'

export const getMaterialStatus = (status: string) => {
  if (status === 'accept') return 'Опубликован'
  if (status === 'denied') return 'Отклонен'
  if (status === 'waiting') return 'Ожидает модерации'

  if (status === 'verification') return 'Модерация'
  if (status === 'rollback') return 'Отсутствует модерация'
  else return status
}

export const getRawPathFromHash = (hash: string) => {
  if (hash) {
    return `${BANK_PHOTO_PATH}/raw/${hash.slice(0, 2)}/${hash.slice(
      2,
      4
    )}/${hash}.jpg`
  } else {
    return ''
  }
}
export const getProtectedPathFromHash = (hash: string) => {
  if (hash) {
    return `${BANK_PHOTO_PATH}/protected/${hash.slice(0, 2)}/${hash.slice(
      2,
      4
    )}/${hash}.jpg`
  } else {
    return ''
  }
}

export const getPathToReportFromHash = (hash: string) => {
  if (hash) {
    return `${BANK_REPORTS_PATH}/${hash.slice(0, 2)}/${hash.slice(
      2,
      4
    )}/${hash}.docx`
  } else {
    return ''
  }
}

export const getNormalizeDate = (date: string) => {
  if (date) {
    return format(parseISO(date), 'dd.MM.yyyy', {
      locale: ru,
    })
  } else {
    return 'Неизвестно'
  }
}

export const getLastDate = () => {
  const today = new Date()
  const yesterday = new Date(today)
  const lastWeek = new Date(today)
  const lastMonth = new Date(today)

  yesterday.setDate(yesterday.getDate() - 1)
  const yesterDayValue = yesterday.toISOString().split('T')[0]
  lastWeek.setDate(lastWeek.getDate() - 7)
  const lastWeekValue = lastWeek.toISOString().split('T')[0]
  lastMonth.setDate(lastMonth.getDate() - 30)
  const lastMonthValue = lastMonth.toISOString().split('T')[0]

  return {
    yesterday: yesterDayValue,
    lastWeek: lastWeekValue,
    lastMonth: lastMonthValue,
  }
}

export const getDateFromISO = (date: string) => {
  return date.split('T')[0]
}

export const getReducedValue = (value: number) => {
  if (value >= 1000000000) {
    return (value / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G'
  }
  if (value >= 1000000) {
    return (value / 1000000).toFixed(1).replace(/\.0$/, '') + 'M'
  }
  if (value >= 1000) {
    return (value / 1000).toFixed(1).replace(/\.0$/, '') + 'K'
  }
  return value
}
