export const BACKEND_URL: string =
  process.env.REACT_APP_BACKEND_URL ?? 'https://api.bank.photo'
export const BANK_PHOTO_PATH =
  process.env.REACT_APP_DATA_URL ?? 'https://bankphototest.s3.amazonaws.com'
export const BANK_REPORTS_PATH =
  process.env.REACT_APP_REPORTS_URL ?? 'https://reports.media4media.ru/reports'

export const CONTRIBUTOR_URL = 'https://contributor.media4media.ru'
export const NOTIFICATION_EXPIRED_DAYS = 7

export const CONTRACT_CHECK_API_PATH = '/consumer/contract/expiration'

export const STATISTIC_API_PATH = '/statistics'
export const SELF_PATH = `/self`
export const SELF_CACHE_PATH = 'self'
export const SELF_STATISTICS = `${STATISTIC_API_PATH}`
export const SELF_STATISTICS_CACHE_PATH = 'statistics'

export const MAIN_PATH = '/greet'
export const MAIN_CACHE_PATH = 'main'

export const FORBIDDEN_PATH = '/forbidden'

export const AUTH_API_PATH = '/auth/login'
export const LOGOUT_API_PATH = 'auth/logout'

export const REGISTRATION_API_PATH = '/registration'
export const REGISTRATION_COMPLETE_PATH = '/registration/complete'

export const CATEGORY_API_PATH = '/category'
export const CATEGORY_CACHE_PATH = 'category'
export const CATEGORY_LIST_API_PATH = `${CATEGORY_API_PATH}/list`
export const CATEGORY_POPULAR_API_PATH = `${CATEGORY_API_PATH}/popul`

export const CONTRIBUTOR_API_PATH = '/contributor'
export const CONTRIBUTOR_LIST_API_PATH = `${CONTRIBUTOR_API_PATH}/list`
export const CONTRIBUTOR_LIST_CACHE_PATH = 'contributors'

export const CONSUMER_API_PATH = '/consumer'

export const MATERIAL_API_PATH = '/material'
export const MATERIAL_NEWS_API_PATH = `${MATERIAL_API_PATH}/news`
export const MATERIALS_PATH = '/materials'
export const MATERIAL_CACHE_PATH = 'material'
export const MATERIAL_NEWS_CACHE_PATH = [MATERIAL_CACHE_PATH, { type: 'news' }]

export const SEARCH_MATERIALS_PATH = '/search'
export const SEARCH_MATERIALS_CACHE_PATH = 'search'

export const PURCHASED_PATH = '/purchased'
export const PURCHASED_CACHE_PATH = 'purchased'
export const PURCHASED_API_PATH = `${MATERIAL_API_PATH}/purchased`

export const FAVOURITE_PATH = '/favourite'
export const FAVOURITE_API_PATH = `${MATERIAL_API_PATH}/favorites`
export const FAVOURITE_CACHE_PATH = 'favourite'

export const VIEWED_API_PATH = `${MATERIAL_API_PATH}/viewed`
export const VIEWED_CACHE_PATH = 'viewed'

export const REPORT_PATH = '/report'
export const REPORT_API_PATH = '/report'
export const REPORT_CACHE_PATH = 'report'

export const AUTHORS_PATH = '/authors'
export const AUTHORS_API_PATH = `${CONTRIBUTOR_API_PATH}/popularity`
export const AUTHORS_CACHE_PATH = 'authors'

export const ABOUT_PATH = '/about'
export const CONTACTS_PATH = '/contacts'
export const INFORMATION_PATH = '/info'
