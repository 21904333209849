import {
  CATEGORY_LIST_API_PATH,
  CONTRIBUTOR_LIST_API_PATH,
  MATERIAL_API_PATH,
} from '../constants/globalConstants'
import { ContributorType, CategoryType } from './types'
import { instance } from './coreAPI'

export const getContributorsListCall = async () => {
  try {
    const response = await instance.get(CONTRIBUTOR_LIST_API_PATH)
    if (response.data?.length) {
      const contributorOptions = response.data.map(
        (author: ContributorType) => ({
          label: author.name,
          value: author.id.toString(),
        })
      )
      return contributorOptions
    } else {
      return []
    }
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const switchFavouriteRequest = async (id: number) => {
  try {
    const response = await instance.post(
      `${MATERIAL_API_PATH}/${id}/toggle/favorite`
    )
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const getAllCategories = async () => {
  try {
    const response = await instance.get(CATEGORY_LIST_API_PATH)
    if (response.data?.length) {
      const categoriesOptions = response.data.map((category: CategoryType) => ({
        id: category.id,
        label: category.category,
        value: category.id.toString(),
      }))
      return categoriesOptions
    } else {
      return []
    }
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
