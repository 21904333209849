import { useQuery } from 'react-query'
import { AUTHORS_CACHE_PATH } from '../../constants/globalConstants'
import { getPopularAuthors } from './AuthorsPage-api'

export const useFetchPopularAuthors = (page: number) => {
  const query = useQuery(
    [AUTHORS_CACHE_PATH, { page: page }],
    async () => await getPopularAuthors(page),
    {
      keepPreviousData: true,
      staleTime: 600000,
      refetchInterval: 600000,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
    }
  )
  return query
}
