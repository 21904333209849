import React from 'react'
import { SkeletonBase } from '../SkeletonBase'
import { SkeletonWindow } from '../SkeletonWindow'
import './SkeletonWindows.sass'

export const SkeletonWindows = () => {
  return (
    <div className='skeleton-windows'>
      <div className='skeleton-windows-content'>
        <SkeletonWindow />
        <SkeletonWindow />
        <SkeletonWindow />
        <SkeletonWindow />
        <SkeletonWindow />
        <SkeletonWindow />
        <SkeletonWindow />
        <SkeletonWindow />
        <SkeletonWindow />
        <SkeletonWindow />
        <SkeletonWindow />
        <SkeletonWindow />
      </div>
      <div className='skeleton-windows-actions'>
        <SkeletonBase
          type='text'
          className='skeleton-windows-actions__pagination'
        />
      </div>
    </div>
  )
}
