import React, { useEffect } from 'react'
import { ReactComponent as SearchImg } from '../../assets/img/search.svg'
import { IoCloseOutline } from 'react-icons/io5'
import { SEARCH_MATERIALS_PATH } from '../../constants/globalConstants'
import queryString from 'query-string'
import './SearchModule.sass'
import classNames from 'classnames'
import { useURLParams } from '../../hooks/useURLParams'
import { useNavigate } from 'react-router'
import {
  SEARCH_URL_VALUE,
  TAGS_URL_VALUE,
} from '../../constants/filterURLNames'
import { InputAutoComplete } from '../../components/InputAutoComplete'
import { tagSearchCall } from '../../api/tagSearchCall'

interface SearchModuleProps {
  className?: string
}

export const SearchModule: React.FC<SearchModuleProps> = ({ className }) => {
  const searchPrfx = 'search'
  const searchCls = classNames(searchPrfx, {
    [`${className}`]: className,
  })

  const navigate = useNavigate()
  const params = useURLParams()

  const search = params[SEARCH_URL_VALUE]

  const [value, setValue] = React.useState(search ? search?.toString() : '')

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value)
  }

  const handleClick = (searchStr: string) => {
    if (searchStr) {
      const trimmedSearchStr = searchStr.trim().replaceAll('#', '')
      const searchParams = {
        [SEARCH_URL_VALUE]: trimmedSearchStr,
      }
      // params[SEARCH_URL_VALUE] = trimmedSearchStr
      navigate({
        pathname: SEARCH_MATERIALS_PATH,
        search: queryString.stringify(searchParams),
      })
    }
  }

  const handleTagClick = (tagStr: string) => {
    if (tagStr) {
      const trimmedTagStr = tagStr.trim().replaceAll('#', '')
      const searchParams = {
        [TAGS_URL_VALUE]: trimmedTagStr,
      }
      // params[SEARCH_URL_VALUE] = trimmedSearchStr
      navigate({
        pathname: SEARCH_MATERIALS_PATH,
        search: queryString.stringify(searchParams),
      })
    }
  }

  const handleEraseClick = () => {
    setValue('')
    navigate({
      pathname: SEARCH_MATERIALS_PATH,
      search: '',
    })
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && !!value) {
      handleClick(value)
    }
  }

  useEffect(() => {
    setValue(search ? search.toString() : '')
  }, [search])

  return (
    <div className={searchCls}>
      <div className='search-field' onKeyPress={handleKeyPress}>
        <InputAutoComplete
          className={`search-field__input`}
          placeholder={'Найдите подходящее фото высокого качества'}
          value={value}
          onChange={handleChange}
          setValue={setValue}
          callback={tagSearchCall}
          handleSubmit={handleTagClick}
        />
        <div className='search-field-icons'>
          {value && (
            <IoCloseOutline
              className='search-field-icons__item search-field-icons__item_close'
              onClick={handleEraseClick}
            />
          )}
          <SearchImg
            className='search-field-icons__item search-field-icons__item_search'
            onClick={() => handleClick(value)}
          />
        </div>
      </div>
    </div>
  )
}
