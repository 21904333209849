import React from 'react'
import { ReactComponent as MailBox } from '../../assets/img/mailbox.svg'
import { Popup } from '../Popup'
import './RegistrationComplete.sass'

export const RegistrationComplete = () => {
  return (
    <Popup>
      <div className='registration-complete'>
        <div className='registration-complete-top'>
          <div className='registration-complete-top-logo'>
            <MailBox className='registration-complete-top-logo__icon' />
          </div>
        </div>
        <div className='registration-complete-bottom'>
          <h2 className='registration-complete-bottom__title'>
            Регистрация завершена
          </h2>
          <div className='registration-complete-bottom__text'>
            Благодарим Вас за регистрацию.
            <br /> В самое ближайшее время администратор проверит Вашу заявку и
            мы обязательно свяжемся с вами по указанным контактным данным.
          </div>
        </div>
      </div>
    </Popup>
  )
}
