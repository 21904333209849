import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { CATEGORY_CACHE_PATH } from '../../constants/globalConstants'
import { getPopularCategories } from './CategoriesModule-api'

export const usePopularCategories = () => {
  const query = useQuery(
    [CATEGORY_CACHE_PATH, { type: 'popular' }],
    async () => await getPopularCategories(),
    {
      keepPreviousData: true,
      staleTime: 600000,
      refetchInterval: 600000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      onError: () => {
        toast.warning('Не удалось загрузить список популярных категорий')
      },
    }
  )
  return query
}
