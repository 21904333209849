import React from 'react'
import surprisedSmile from '../../assets/img/surprised.png'
import './IncorretResult.sass'

export const IncorretResult = () => {
  return (
    <div className='incorrect-result'>
      <img
        src={surprisedSmile}
        alt='Удивленный смайл'
        className='incorrect-result__img'
      />
      <h2 className='incorrect-result__title'>
        Мы не смоги получить данные по вашему запросу
      </h2>
      <h2 className='incorrect-result__text'>
        Пожалуйста, обратитесь к администратору за помощью
      </h2>
    </div>
  )
}
