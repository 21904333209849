import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import {
  MATERIAL_CACHE_PATH,
  MATERIAL_NEWS_CACHE_PATH,
} from '../../../../constants/globalConstants'
import { switchFavouriteRequest } from '../../../../utils/sharedApi'
import {
  MaterialPreviewType,
  MaterialsResponseType,
} from '../../../../utils/types'
import { getGuestMaterials } from './GalleryModule-api'

export const useFetchGuestMaterials = (isAuth: boolean) => {
  const query = useQuery(
    [MATERIAL_CACHE_PATH, { type: 'main' }, { isAuth }],
    async () => await getGuestMaterials(),
    {
      keepPreviousData: true,
      staleTime: 600000,
      refetchInterval: 600000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onError: () => {
        toast.warning('Не удалось загрузить галерею изображений')
      },
    }
  )
  return query
}

export const useFavouriteItem = (isAuth: boolean) => {
  const client = useQueryClient()
  const mainGalleryKey = [
    `${MATERIAL_CACHE_PATH}`,
    { type: 'main' },
    { isAuth },
  ]
  const mutation = useMutation((id: number) => switchFavouriteRequest(id), {
    onMutate: async (id) => {
      const oldMaterialsInfo: MaterialsResponseType | undefined =
        await client.getQueryData(mainGalleryKey)

      if (oldMaterialsInfo === undefined) {
        return
      }

      const updatedMaterials = {
        items: oldMaterialsInfo.items.map((item: MaterialPreviewType) => {
          if (item.id === id) {
            return {
              ...item,
              isFavorite: !item.isFavorite,
            }
          } else {
            return item
          }
        }),
        total: oldMaterialsInfo.total,
        page: oldMaterialsInfo.page,
        size: oldMaterialsInfo.size,
      }
      client.setQueryData(mainGalleryKey, updatedMaterials)
      return { oldMaterialsInfo }
    },
    onError: (err, newTodo, context: any) => {
      client.setQueryData(mainGalleryKey, context.oldMaterialsInfo)
      toast.error('Не удалось изменить статус материала')
    },
    onSettled: async () => {
      await client.invalidateQueries(MATERIAL_NEWS_CACHE_PATH)
      // await client.invalidateQueries(MATERIAL_CACHE_PATH)
    },
  })
  return mutation
}
