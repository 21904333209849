import React from 'react'
import './MainPage.sass'
import { GalleryModule } from './modules/GalleryModule'
import { NewsModule } from './modules/NewsModule'
import { SearchModule } from '../../modules/SearchModule'
import { CategoriesModule } from '../../modules/CategoriesModule'

export const MainPage = () => {
  return (
    <div className='section-main bottom-indent'>
      <CategoriesModule />
      <div className='section-outer'>
        <div className='section-inner main-page'>
          <SearchModule className='main-page__search' />
          <h1 className='main-page__title'>
            1.000.000+ фотографий для СМИ и медиа
          </h1>
          <h2 className='main-page__subtitle'>От авторов со всей страны</h2>
          <NewsModule className='main-page__news' />
          <GalleryModule className='main-page__gallery' />
        </div>
      </div>
    </div>
  )
}
