import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { MATERIAL_CACHE_PATH } from '../../../../constants/globalConstants'
import {
  downloadFileRequest,
  purchaseItemRequest,
} from './UserMaterialActionModule-api'

export const usePurchaseItem = () => {
  const client = useQueryClient()
  const mutation = useMutation((id: number) => purchaseItemRequest(id), {
    onSuccess: async (data, variables, context) => {
      await client.invalidateQueries([MATERIAL_CACHE_PATH, { id: variables }])
      toast.info('Материал приобретен')
    },
    onError: (error, values, context?: any) => {
      toast.error('Не удалось приобрести материал')
    },
  })
  return mutation
}

export const useDownloadItem = () => {
  const mutation = useMutation(
    (fileInfo: { id: number; path: string }) =>
      downloadFileRequest(fileInfo.path),
    {
      onSuccess: async (data, variables, context) => {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        const fileName = `material${variables.id}.jpg`
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        link.remove()
      },
      onError: (error: any) => {
        toast.error(error ?? 'Не удалось загрузить файл')
      },
    }
  )
  return mutation
}
