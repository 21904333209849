import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { MATERIAL_CACHE_PATH } from '../../../../constants/globalConstants'
import { switchFavouriteRequest } from '../../../../utils/sharedApi'
import {
  MaterialPreviewType,
  MaterialsResponseType,
} from '../../../../utils/types'
import { getGuestMaterials } from './GalleryModule-api'

export const useFetchGuestMaterials = (
  page: number = 1,
  category: string | undefined,
  tags: string,
  searchStr: string,
  filterDate: string | null,
  sortOrder: string | undefined,
  filterAuthor: string | null,
  isAuth: boolean
) => {
  const query = useQuery(
    [
      MATERIAL_CACHE_PATH,
      {
        type: 'gallery',
        page,
        category,
        tags,
        searchStr,
        filterDate,
        sortOrder,
        filterAuthor,
        isAuth,
      },
    ],
    async () =>
      await getGuestMaterials(
        page,
        category,
        tags,
        searchStr,
        filterDate,
        sortOrder,
        filterAuthor
      ),
    {
      keepPreviousData: true,
      staleTime: 600000,
      refetchInterval: 600000,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
    }
  )
  return query
}

export const useFavouriteItem = (queryKey: any[]) => {
  const client = useQueryClient()
  const mutation = useMutation((id: number) => switchFavouriteRequest(id), {
    onMutate: async (id) => {
      const oldMaterialsInfo: MaterialsResponseType | undefined =
        await client.getQueryData(queryKey)

      if (oldMaterialsInfo === undefined) {
        return
      }

      const updatedMaterials = {
        items: oldMaterialsInfo.items.map((item: MaterialPreviewType) => {
          if (item.id === id) {
            return {
              ...item,
              isFavorite: !item.isFavorite,
            }
          } else {
            return item
          }
        }),
        total: oldMaterialsInfo.total,
        page: oldMaterialsInfo.page,
        size: oldMaterialsInfo.size,
      }
      client.setQueryData(queryKey, updatedMaterials)
      return { oldMaterialsInfo }
    },
    onError: (err, newTodo, context: any) => {
      client.setQueryData(queryKey, context.oldMaterialsInfo)
      toast.error('Не удалось изменить статус')
    },
    onSettled: async () => {
      // await client.invalidateQueries(MATERIAL_CACHE_PATH)
    },
  })
  return mutation
}
