import classNames from 'classnames'
import React from 'react'
import queryString from 'query-string'
import { SEARCH_MATERIALS_PATH } from '../../constants/globalConstants'
import { NewsArrayType, TagsType } from '../../utils/types'
import './NewsComponent.sass'
import { useNavigate } from 'react-router'

interface NewsComponentProps {
  className?: string
  news: NewsArrayType
}

export const NewsComponent: React.FC<NewsComponentProps> = ({
  className,
  news,
}) => {
  const navigate = useNavigate()
  const newsPrfx = 'news-component'
  const newsCls = classNames(newsPrfx, {
    [`${className}`]: className,
  })
  const handleClick = (tags: TagsType) => {
    const params = { tags: tags.join(' ') }
    navigate({
      pathname: SEARCH_MATERIALS_PATH,
      search: queryString.stringify(params),
    })
  }
  return (
    <div className={newsCls}>
      <h2 className={`${newsPrfx}__title`}>Повестка дня</h2>
      <ul className={`${newsPrfx}-list`}>
        {news.map((news, idx) => (
          <li
            className={`${newsPrfx}-list__item`}
            key={idx}
            onClick={() => handleClick(news.tags)}
          >
            {news.title}
          </li>
        ))}
      </ul>
    </div>
  )
}
