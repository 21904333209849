import classNames from 'classnames'
import React from 'react'
import { Button } from '../../../../components/Button'
import { ModerationBtn } from '../../../../components/ModerationBtn'
// import { Checkbox } from '../../../../components/Checkbox'
import { getRawPathFromHash } from '../../../../utils/utils'
import {
  useDownloadItem,
  usePurchaseItem,
} from './UserMaterialActionModule-hooks'
import './UserMaterialActionModule.sass'

interface UserMaterialActionModuleProps {
  className?: string
  id: number
  isPurchased: boolean
  isFavourite: boolean
  rawHash: string | undefined
  isModerationVisible: boolean
}

export const UserMaterialActionModule: React.FC<
  UserMaterialActionModuleProps
> = ({
  className,
  id,
  isPurchased,
  isFavourite,
  rawHash,
  isModerationVisible,
}) => {
  const userMaterialPrfx = 'user-material-download'
  const userMaterialCls = classNames(userMaterialPrfx, {
    [`${className}`]: className,
  })

  const { mutateAsync: buyMaterial, isLoading: loadingPurchasing } =
    usePurchaseItem() as {
      mutateAsync: (id: number) => Promise<any>
      isLoading: boolean
    }
  const { mutateAsync: downloadMaterial, isLoading: loadingDownload } =
    useDownloadItem() as {
      mutateAsync: (fileInfo: { id: number; path: string }) => Promise<any>
      isLoading: boolean
    }

  const rawPath = rawHash ? getRawPathFromHash(rawHash) : ''

  const handleDownload = async () => {
    if (rawHash) {
      downloadMaterial({ id, path: rawPath })
    }
  }

  const handleBuy = () => {
    if (id) {
      buyMaterial(id)
    }
  }

  const downloadBody = () => {
    return (
      <>
        <Button
          className={`${userMaterialPrfx}__btn`}
          disabled={!rawPath}
          loading={loadingDownload}
          onClick={handleDownload}
        >
          Загрузить
        </Button>
      </>
    )
  }
  const purchaseBody = () => {
    return (
      <>
        <Button
          className={`${userMaterialPrfx}__btn`}
          loading={loadingPurchasing}
          onClick={handleBuy}
        >
          Купить
        </Button>
      </>
    )
  }

  return (
    <div className={userMaterialCls}>
      {isPurchased ? downloadBody() : purchaseBody()}
      {isModerationVisible && <ModerationBtn id={id} />}
    </div>
  )
}
