import classNames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'
import { SEARCH_MATERIALS_PATH } from '../../constants/globalConstants'
import { CategoryType } from '../../utils/types'
import './MaterialDetailCategory.sass'

interface MaterialDetailCategoryProps {
  className?: string
  category: CategoryType
}

export const MaterialDetailCategory: React.FC<MaterialDetailCategoryProps> = ({
  className,
  category,
}) => {
  const detailPrfx = 'material-details-category'
  const detailCls = classNames(detailPrfx, {
    [`${className}`]: className,
  })

  if (!category?.id) {
    return null
  }

  return (
    <div className={`${detailCls}`}>
      <Link
        to={`${SEARCH_MATERIALS_PATH}?categories=${category.id}`}
        className={`${detailPrfx}__text`}
      >
        {category.category}
      </Link>
    </div>
  )
}
