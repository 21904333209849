import React, { useCallback } from 'react'
import { useNavigate } from 'react-router'
// import queryString from 'query-string'
// import { GET_PARAMS } from '../../constants/modals'
// import { useURLParams } from '../../hooks/useURLParams'
import { Portal } from '../Portal'
import './Popup.sass'

interface PopupProps {}

export const Popup: React.FC<PopupProps> = ({ children }) => {
  // const params = useURLParams()
  const navigate = useNavigate()
  // const handleCancel = useCallback(() => {
  //   delete params[GET_PARAMS.popup]
  //   navigate({
  //     search: queryString.stringify(params),
  //   })
  // }, [params, navigate])
  const handleCancel = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const handleEscClick = React.useCallback(
    (e: any) => {
      if ((e.charCode || e.keyCode) === 27) {
        handleCancel()
      }
    },
    [handleCancel]
  )
  React.useEffect(() => {
    document.body.addEventListener('keydown', handleEscClick)
    return function cleanup() {
      document.body.removeEventListener('keydown', handleEscClick)
    }
  }, [handleEscClick])

  React.useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])
  return (
    <Portal>
      <div className='popup' role='dialog'>
        <div
          className='overlay'
          role='button'
          tabIndex={0}
          onClick={handleCancel}
        />
        <div
          className='popup__close'
          role='button'
          tabIndex={1}
          onClick={handleCancel}
        />
        <div className='popup__content'>{children}</div>
      </div>
    </Portal>
  )
}
