export const filterDateOptions = [
  {
    label: 'За все время',
    value: undefined,
  },
  {
    label: 'За последние 24 часа',
    value: '1d',
  },
  {
    label: 'За последнюю неделю',
    value: '1w',
  },
  {
    label: 'За последний месяц',
    value: '1m',
  },
  {
    label: 'За последний год',
    value: '1y',
  },
]
