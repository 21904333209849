import classNames from 'classnames'
import React from 'react'
import { Button } from '../Button'
import './ModerationBtn.sass'

interface ModerationBtnProps {
  className?: string
  id: number
}

export const ModerationBtn: React.FC<ModerationBtnProps> = ({
  className,
  id,
}) => {
  const itemPrfx = 'moderation-btn'
  const itemCls = classNames(itemPrfx, {
    [`${className}`]: className,
  })

  const handleClick = () => {
    window.open(`https://admin.media4media.ru/moderation?moderationId=${id}`)
  }

  return (
    <Button className={itemCls} onClick={handleClick}>
      Перемодерация
    </Button>
  )
}
