import { MAIN_PATH, REPORT_PATH } from '../../constants/globalConstants'

export const REPORTS_COUNT = 12

export const breadCrumbsData = [
  {
    id: 0,
    name: 'Кабинет администратора',
    path: MAIN_PATH,
  },
  {
    id: 1,
    name: 'Управление отчетами',
    path: REPORT_PATH,
  },
]
