import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import {
  CATEGORY_CACHE_PATH,
  CONTRIBUTOR_LIST_CACHE_PATH,
} from '../constants/globalConstants'
import { getAllCategories, getContributorsListCall } from './sharedApi'

export const useFetchContributorList = () => {
  const query = useQuery(
    [CONTRIBUTOR_LIST_CACHE_PATH],
    async () => await getContributorsListCall(),
    {
      keepPreviousData: true,
      // staleTime: 600000,
      // refetchInterval: 600000,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      onError: () => {
        toast.warning('Не удалось загрузить список авторов')
      },
    }
  )
  return query
}

export const useCategories = () => {
  const query = useQuery(
    CATEGORY_CACHE_PATH,
    async () => await getAllCategories(),
    {
      keepPreviousData: true,
      staleTime: 600000,
      refetchInterval: 600000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      onError: () => {
        toast.warning('Не удалось загрузить список категорий')
      },
    }
  )
  return query
}
