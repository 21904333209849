import { toast } from 'react-toastify'
import {
  AUTH_API_PATH,
  CONTRACT_CHECK_API_PATH,
  LOGOUT_API_PATH,
  NOTIFICATION_EXPIRED_DAYS,
} from '../../constants/globalConstants'
import { isExpiredDateTooClose } from '../../utils/check'
import { instance } from '../../utils/coreAPI'
import { Credencials } from './AuthForm-types'

export const signinCall = async (credencials: Credencials) => {
  const formData = new FormData()
  formData.append('username', credencials.username)
  formData.append('password', credencials.password)
  formData.append('client_id', 'consm')
  try {
    const response = await instance.post(AUTH_API_PATH, formData)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error ?? 'Проблема соединения с сервером')
  }
}

export const contractCheck = async () => {
  try {
    const response = await instance.get(CONTRACT_CHECK_API_PATH)
    const { contractExpiration } = response.data
    const isNotification = isExpiredDateTooClose(contractExpiration)
    if (isNotification) {
      toast.warning(
        `До истечении вашего контракта осталось меньше ${NOTIFICATION_EXPIRED_DAYS} дней`
      )
    }
    return null
  } catch (error: any) {
    return await Promise.reject(error ?? 'Проблема соединения с сервером')
  }
}

export const logoutCall = async () => {
  try {
    instance.post(LOGOUT_API_PATH)
    return null
  } catch (error: any) {
    return await Promise.reject(error ?? 'Проблема соединения с сервером')
  }
}
