import { Routes, Route, Navigate, Outlet } from 'react-router'
import './assets/styles/App.sass'
import {
  ABOUT_PATH,
  AUTHORS_PATH,
  CONTACTS_PATH,
  FAVOURITE_PATH,
  FORBIDDEN_PATH,
  INFORMATION_PATH,
  MAIN_PATH,
  MATERIALS_PATH,
  PURCHASED_PATH,
  REGISTRATION_COMPLETE_PATH,
  REPORT_PATH,
  SEARCH_MATERIALS_PATH,
} from './constants/globalConstants'
import { Footer } from './modules/Footer'
import { Header } from './modules/Header'
import { MainPage } from './pages/MainPage'
import { DetailsPage } from './pages/DetailsPage'
import { SearchPage } from './pages/SearchPage'
import { AuthorsPage } from './pages/AuthorsPage'
import { FavouritePage } from './pages/FavouritePage'
import { PurchasedPage } from './pages/PurchasedPage'
import { ForbiddenPage } from './pages/ForbiddenPage'
import { ScrollToTop } from './components/ScrollToTop'
import { RequireAuth } from './components/RequireAuth'
import { GetParameterPopup } from './components/GetParameterPopup'
import { ReportPage } from './pages/ReportPage'
import { ReportAdd } from './pages/ReportPage/modules/ReportAdd'
import { ImgFullScreen } from './components/ImgFullScreen'
import { RegistrationComplete } from './components/RegistrationComplete'
import { AboutPage } from './pages/AboutPage'
import { ContactsPage } from './pages/ContactsPage'
import { InformationPage } from './pages/InformationPage'

export const AppRoutes: React.FC = () => {
  return (
    <div className='page'>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path={MAIN_PATH} element={<MainPage />} />
        <Route path={MATERIALS_PATH} element={<Outlet />}>
          <Route path={`:id`} element={<Outlet />}>
            <Route index element={<DetailsPage />} />
            <Route path={'view'} element={<ImgFullScreen />} />
          </Route>
        </Route>
        <Route path={SEARCH_MATERIALS_PATH} element={<SearchPage />} />
        <Route path={REPORT_PATH} element={<Outlet />}>
          <Route index element={<ReportPage />} />
          <Route path='new' element={<ReportAdd />} />
        </Route>

        <Route path={ABOUT_PATH} element={<AboutPage />} />
        <Route path={CONTACTS_PATH} element={<ContactsPage />} />
        <Route path={INFORMATION_PATH} element={<InformationPage />} />

        <Route path={FORBIDDEN_PATH} element={<ForbiddenPage />} />

        <Route element={<RequireAuth />}>
          <Route path={AUTHORS_PATH} element={<AuthorsPage />} />
          <Route path={FAVOURITE_PATH} element={<FavouritePage />} />
          <Route path={PURCHASED_PATH} element={<PurchasedPage />} />
        </Route>
        <Route
          path={REGISTRATION_COMPLETE_PATH}
          element={<RegistrationComplete />}
        />
        <Route path='*' element={<Navigate to={MAIN_PATH} />} />
      </Routes>
      <GetParameterPopup />
      <Footer />
    </div>
  )
}
