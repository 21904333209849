import { useMemo } from 'react'
import { useLocation } from 'react-router'

export const useURLParams = () => {
  const { search } = useLocation()
  const searchParams = useMemo(
    () => Object.fromEntries(new URLSearchParams(search)),
    [search]
  )
  return searchParams
}
