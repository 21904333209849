import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { ReactComponent as Logo } from '../../assets/img/ruj-logo.svg'
import './Footer.sass'
import {
  ABOUT_PATH,
  AUTHORS_PATH,
  CONTACTS_PATH,
  FAVOURITE_PATH,
  INFORMATION_PATH,
  MAIN_PATH,
} from '../../constants/globalConstants'
import { RootState } from '../../reducers'
import { GET_ENUMS, GET_PARAMS } from '../../constants/modals'
import { useLogout } from '../AuthForm/AuthForm-hooks'

export const Footer = () => {
  const isAuth = useSelector((state: RootState) => state.auth.isAuth)
  const { mutate: logout } = useLogout() as {
    mutate: () => void
  }
  const navigate = useNavigate()
  const linkCls = 'pb-link'
  const linkActiveCls = `${linkCls}_active`

  const getAuthLink = () => {
    if (!isAuth) {
      return (
        <NavLink
          to=''
          onClick={() =>
            navigate(`?${GET_PARAMS.popup}=${GET_ENUMS.popup.signin}`)
          }
        >
          Войти
        </NavLink>
      )
    } else {
      return (
        <NavLink to='' onClick={logout}>
          Выйти
        </NavLink>
      )
    }
  }

  return (
    <footer className='section-outer section-footer'>
      <div className='section-inner footer'>
        <div className='footer-content'>
          <div className='footer-content-main footer-content-block'>
            <h2 className='footer-content-block__title'>Партнеры</h2>
            <a
              className='footer-content-main__body'
              href='https://ruj.ru'
              rel='noreferrer'
              target='_blank'
            >
              <Logo className='footer-content-main__logo' />
              <span className='footer-content-main__text'>
                Союз
                <br />
                Журналистов
                <br />
                России
                <br />
              </span>
            </a>
          </div>
          <div className='footer-content-info footer-content-block'>
            <h2 className='footer-content-info__title footer-content-block__title'>
              Стартовая
            </h2>
            <ul className='footer-content-info-menu footer-content-block-menu'>
              <li className='footer-content-info-menu__item footer-content-block-menu__item'>
                <NavLink
                  to={MAIN_PATH}
                  className={(props) => {
                    return props.isActive ? linkActiveCls : linkCls
                  }}
                >
                  Главная страница
                </NavLink>
              </li>
              <li className='footer-content-info-menu__item footer-content-block-menu__item'>
                <NavLink
                  to={ABOUT_PATH}
                  className={(props) => {
                    return props.isActive ? linkActiveCls : linkCls
                  }}
                >
                  О нас
                </NavLink>
              </li>
              <li className='footer-content-info-menu__item footer-content-block-menu__item'>
                <NavLink
                  to={CONTACTS_PATH}
                  className={(props) => {
                    return props.isActive ? linkActiveCls : linkCls
                  }}
                >
                  Контакты
                </NavLink>
              </li>
              <li className='footer-content-info-menu__item footer-content-block-menu__item'>
                <NavLink
                  to={INFORMATION_PATH}
                  className={(props) => {
                    return props.isActive ? linkActiveCls : linkCls
                  }}
                >
                  Информация
                </NavLink>
              </li>
            </ul>
          </div>
          <div className='footer-content-account footer-content-block'>
            <h2 className='footer-content-info__title footer-content-block__title'>
              Кабинет
            </h2>
            <ul className='footer-content-info-menu footer-content-block-menu'>
              <li className='footer-content-info-menu__item footer-content-block-menu__item'>
                {getAuthLink()}
              </li>
              {isAuth && (
                <li className='footer-content-info-menu__item footer-content-block-menu__item'>
                  <NavLink
                    to={FAVOURITE_PATH}
                    className={(props) => {
                      return props.isActive ? linkActiveCls : linkCls
                    }}
                  >
                    Избранные
                  </NavLink>
                </li>
              )}
              {isAuth && (
                <li className='footer-content-info-menu__item footer-content-block-menu__item'>
                  <NavLink
                    to={AUTHORS_PATH}
                    className={(props) => {
                      return props.isActive ? linkActiveCls : linkCls
                    }}
                  >
                    Авторы
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
        </div>

        <div className='footer-copyrights'>
          Copyright. © 2021-2024 Media4Media Company. All rights reserved.
        </div>
      </div>
    </footer>
  )
}
