import classNames from 'classnames'
import React from 'react'
import { ReactComponent as CheckIcon } from '../../assets/img/logo_check.svg'
import './ExclusiveRightsComponent.sass'

interface ExclusiveRightsComponentProps {
  className?: string
}

export const ExclusiveRightsComponent: React.FC<
  ExclusiveRightsComponentProps
> = ({ className }) => {
  const itemPrfx = 'exclusive-rights-component'
  const itemCls = classNames(itemPrfx, className)
  return (
    <div className={itemCls}>
      <CheckIcon className={`${itemPrfx}__icon`} />
      <span className={`${itemPrfx}__text`}>Исключительные права</span>
    </div>
  )
}
