import classNames from 'classnames'
import React from 'react'
import { useNavigate } from 'react-router'
// import { saveAs } from 'file-saver'
import { Button } from '../../../../components/Button'
import { ModerationBtn } from '../../../../components/ModerationBtn'
import { GET_ENUMS, GET_PARAMS } from '../../../../constants/modals'
import './GuestMaterialActionModule.sass'

interface GuestMaterialActionModuleProps {
  className?: string
  isModerationVisible: boolean
  id: number
}

export const GuestMaterialActionModule: React.FC<GuestMaterialActionModuleProps> =
  React.memo(({ className, isModerationVisible, id }) => {
    const navigate = useNavigate()
    const guestMaterialPrfx = 'guest-material-download'
    const guestMaterialCls = classNames(guestMaterialPrfx, {
      [`${className}`]: className,
    })

    const handleClick = () => {
      navigate(`?${GET_PARAMS.popup}=${GET_ENUMS.popup.signin}`)
    }

    return (
      <div className={guestMaterialCls}>
        <Button className={`${guestMaterialPrfx}__btn`} onClick={handleClick}>
          Купить
        </Button>
        {isModerationVisible && <ModerationBtn id={id} />}
      </div>
    )
  })
