import classNames from 'classnames'
import React from 'react'
import { AiOutlineStar, AiFillStar } from 'react-icons/ai'
import { NavLink } from 'react-router-dom'
import { ContributorType, CategoryType } from '../../utils/types'
import { getNormalizeDate } from '../../utils/utils'
import { Button } from '../Button'
import './ImgStatWrapper.sass'

interface ImgStatWrapperProps {
  className?: string
  pathTo: string
  blank?: boolean
  onFavouriteClick?: (id: number) => void
  date: string
  contributor: ContributorType
  category: CategoryType
  isFavourite?: boolean
}

export const ImgStatWrapper: React.FC<ImgStatWrapperProps> = ({
  className,
  date,
  contributor,
  category,
  isFavourite,
  pathTo,
  blank = false,
  onFavouriteClick,
}) => {
  const divPrfx = 'img-stat-wrapper'
  const divCls = classNames(divPrfx, 'cls_981374', {
    [`${className}`]: className,
  })
  const normalDate = getNormalizeDate(date)
  return (
    <div className={divCls}>
      <NavLink
        to={pathTo}
        target={blank ? '_blank' : ''}
        className={`${divPrfx}-statistics`}
      >
        <span className={`${divPrfx}-statistics__date`}>{normalDate}</span>
        <span className={`${divPrfx}-statistics__author`}>
          Автор: {contributor.name}
        </span>
        <span className={`${divPrfx}-statistics__category`}>
          Категория: {category.category}
        </span>
      </NavLink>
      {isFavourite !== undefined && (
        <Button
          type='transparent'
          className={`${divPrfx}__favourite`}
          short
          onClick={onFavouriteClick}
        >
          <span className={`${divPrfx}__favourite-title`}>
            {isFavourite ? <AiFillStar /> : <AiOutlineStar />}
          </span>
        </Button>
      )}
    </div>
  )
}
