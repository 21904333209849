import classNames from 'classnames'
import React from 'react'
import { FaRegUser } from 'react-icons/fa'
import { useProfile } from '../../hooks/useProfile'
import './UserIcon.sass'

interface UserIconProps {
  className?: string
}

export const UserIcon: React.FC<UserIconProps> = ({ className }) => {
  const userPrfx = 'user-icon'
  const divCls = classNames(userPrfx, {
    [`${className}`]: className,
  })

  const handleOpenProfile = useProfile()

  return (
    <div className={divCls} onClick={handleOpenProfile} role='button'>
      <FaRegUser />
    </div>
  )
}
