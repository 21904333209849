import classNames from 'classnames'
import React from 'react'
import { useOnClickOutside } from '../../utils/hooks'
import { OptionType } from '../../utils/types'
import './CategoriesAll.sass'

interface CategoriesAllProps {
  className?: string
  categories: OptionType[] | null
  currentCategoryId: string | undefined
  isVisible: boolean
  handleCategoryClick: (category: OptionType) => void
  closeMenu: () => void
}

export const CategoriesAll: React.FC<CategoriesAllProps> = ({
  className,
  categories,
  currentCategoryId,
  isVisible,
  handleCategoryClick,
  closeMenu,
}) => {
  const divRef = React.useRef<HTMLDivElement>(null)
  useOnClickOutside(divRef, closeMenu)

  const wrapperPrfx = 'categories-wrapper'
  const categoriesPrfx = 'categories-list'
  const categoryPrfx = `${categoriesPrfx}__item`

  const getCategoryCls = (id: string | null) => {
    if (`${id}` === currentCategoryId) {
      return `${categoryPrfx} ${categoryPrfx}_active`
    } else {
      return `${categoryPrfx}`
    }
  }

  const divCls = classNames(wrapperPrfx, {
    [`${className}`]: className,
    [`${wrapperPrfx}_hidden`]: isVisible === false,
  })

  const handleChangeCategory = (category: OptionType) => {
    handleCategoryClick(category)
    closeMenu()
  }

  React.useEffect(() => {
    window.addEventListener('keydown', closeMenu, false)
    return () => {
      window.removeEventListener('keydown', closeMenu, false)
    }
  }, [closeMenu])

  if (!categories) return <></>

  return (
    <div className={divCls} ref={divRef}>
      <div className={categoriesPrfx}>
        {categories.map((category) => (
          <div
            className={getCategoryCls(category?.value)}
            key={category.id}
            onClick={() => handleChangeCategory(category)}
          >
            {category.label}
          </div>
        ))}
      </div>
    </div>
  )
}
