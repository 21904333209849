import { MATERIAL_API_PATH } from '../../../../constants/globalConstants'
import { instance } from '../../../../utils/coreAPI'
import { GALLERY_SIZE } from './GalleryModule-constants'

export const getGuestMaterials = async () => {
  const config = {
    params: {
      page: 1,
      size: GALLERY_SIZE,
      sort: 'desc.pop',
    },
  }
  try {
    const response = await instance.get(MATERIAL_API_PATH, config)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
