import {
  USER_NAME_STORAGE_KEY,
  USER_PERMISSIONS_STORAGE_KEY,
  USER_TOKEN_STORAGE_KEY,
} from '../constants/storage'

export const getToken = () => {
  const token = localStorage.getItem(USER_TOKEN_STORAGE_KEY)
  return token ? token : ''
}

export const setToken = (value: string) => {
  localStorage.setItem(USER_TOKEN_STORAGE_KEY, value)
}

export const getPermissions = () => {
  const permissions = localStorage.getItem(USER_PERMISSIONS_STORAGE_KEY)
  return permissions ?? ''
}

export const setPermissions = (value: string) => {
  localStorage.setItem(USER_PERMISSIONS_STORAGE_KEY, value)
}

export const getUserName = () => {
  const username = localStorage.getItem(USER_NAME_STORAGE_KEY)
  return username ?? ''
}

export const setUserName = (value: string) => {
  localStorage.setItem(USER_NAME_STORAGE_KEY, value)
}

export const LSClear = () => {
  localStorage.removeItem(USER_TOKEN_STORAGE_KEY)
  localStorage.removeItem(USER_PERMISSIONS_STORAGE_KEY)
  localStorage.removeItem(USER_NAME_STORAGE_KEY)
}
