import { isAuthenticated } from '../../utils/auth'
import { getPermissions, getToken, getUserName } from '../../utils/storage'
import { AUTH_DATA_RESET, AUTH_DATA_SET } from './AuthForm-constants'
import { AuthActions, AuthState } from './AuthForm-types'

const initialState: AuthState = {
  token: getToken(),
  isAuth: isAuthenticated(),
  permissions: getPermissions(),
  username: getUserName(),
}

export const AuthReducer = (
  state = initialState,
  action: AuthActions
): AuthState => {
  switch (action.type) {
    case AUTH_DATA_SET: {
      return {
        ...state,
        token: action.payload.token,
        permissions: action.payload.permissions,
        username: action.payload.username,
        isAuth: true,
      }
    }
    case AUTH_DATA_RESET:
      return {
        ...state,
        token: '',
        username: '',
        isAuth: false,
        permissions: '',
      }
    default:
      return state
  }
}
