import classNames from 'classnames'
import React from 'react'
import { ControlWrapperPrefixCls } from './Input-constants'
import { InputProps } from './Input-types'
import './InputB.sass'

export const InputB: React.FC<InputProps> = ({
  className,
  type,
  size = 'medium',
  placeholder,
  id,
  ...rest
}) => {
  const inputClassName = 'input_borderless'
  const controlClassName = classNames(ControlWrapperPrefixCls, {
    [`${className}`]: className,
    [`${ControlWrapperPrefixCls}_size_sm`]: size === 'small',
    [`${ControlWrapperPrefixCls}_size_lg`]: size === 'large',
  })
  return (
    <div className={controlClassName}>
      <input
        type={type}
        id={id}
        className={inputClassName}
        placeholder={placeholder}
        {...rest}
      />
    </div>
  )
}
