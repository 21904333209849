import classNames from 'classnames'
import React from 'react'
import { SkeletonBase } from '../SkeletonBase'
import './SkeletonActions.sass'

interface SkeletonActionsProps {
  className?: string
  withButton: boolean
}

export const SkeletonActions: React.FC<SkeletonActionsProps> = ({
  className,
  withButton,
}) => {
  const actionsPrfx = 'skeleton-actions'
  const actionsCls = classNames(actionsPrfx, {
    [`${className}`]: className,
  })
  return (
    <div className={actionsCls}>
      {withButton && (
        <SkeletonBase className={`${actionsPrfx}__btn`} type='text' />
      )}
      <SkeletonBase type='text' className={`${actionsPrfx}__pagination`} />
    </div>
  )
}
