import classNames from 'classnames'
import React from 'react'
import { useTable } from 'react-table'
import './Table.sass'

interface TableProps {
  className?: string
  columns: any
  data: any
  colored?: boolean
  handleRowClick?: (id: number) => void
}

export const Table: React.FC<TableProps> = ({
  className,
  columns,
  data,
  colored,
  handleRowClick,
}) => {
  const tdCls = classNames('cell', {
    cell_colored: colored,
  })
  const tableCls = classNames('table', {
    [`${className}`]: className,
  })

  const tableInstance = useTable({
    columns,
    data,
  })

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance

  const emptyBody = React.useMemo(() => {
    const tdLength = columns.length
    return (
      <tbody>
        <tr>
          <td className='empty-cell' colSpan={tdLength}>
            По вашему запросу нет данных
          </td>
        </tr>
      </tbody>
    )
  }, [columns])

  return (
    <>
      <table {...getTableProps()} className={tableCls}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>

        {data.length > 0 ? (
          <tbody {...getTableBodyProps}>
            {rows.map((row: any) => {
              prepareRow(row)
              return (
                <tr
                  {...row.getRowProps()}
                  onClick={
                    handleRowClick && (() => handleRowClick(row.original.id))
                  }
                >
                  {row.cells.map((cell: any) => {
                    return (
                      <td className={tdCls} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        ) : (
          emptyBody
        )}
      </table>
    </>
  )
}
