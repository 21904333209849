import classNames from 'classnames'
import React from 'react'
import Select from 'react-select'
import queryString from 'query-string'
import { useCategories } from '../../utils/dataHooks'
import { OptionType } from '../../utils/types'
import { getCategoryOption } from './FilterCategory-utils'
import { NavigateFunction } from 'react-router'
import {
  CATEGORY_URL_VALUE,
  PAGE_URL_VALUE,
} from '../../constants/filterURLNames'

interface FilterCategoryProps {
  className?: string
  navigate: NavigateFunction
  params: { [k: string]: string }
}

export const FilterCategory: React.FC<FilterCategoryProps> = ({
  className,
  navigate,
  params,
}) => {
  const filterPrfx = 'filter-param-container'
  const filterCls = classNames(filterPrfx, {
    [`${className}`]: className,
  })
  const categoryId = params[CATEGORY_URL_VALUE]

  const { data: categoriesList, isLoading: isCategoriesLoading } =
    useCategories() as {
      data: OptionType[]
      isLoading: boolean
    }

  const categoryOption = React.useCallback(
    () => getCategoryOption(categoryId, categoriesList),
    [categoryId, categoriesList]
  )

  const [categoryFilter, setCategoryFilter] = React.useState(categoryOption)

  React.useEffect(() => {
    setCategoryFilter(categoryOption)
  }, [categoryId, categoryOption])

  const handleFilterCategoryChange = (option: any) => {
    setCategoryFilter(getCategoryOption(option.value, categoriesList))
    params[CATEGORY_URL_VALUE] = option.value
    params[PAGE_URL_VALUE] = '1'
    navigate({ search: queryString.stringify(params) })
  }

  return (
    <div className={filterCls}>
      <label className={`${filterPrfx}__label`} htmlFor='category'>
        По категории
      </label>
      <Select
        id='category'
        className='react-select-container'
        classNamePrefix='react-select'
        placeholder={'Категория...'}
        value={categoryFilter}
        onChange={handleFilterCategoryChange}
        isLoading={isCategoriesLoading}
        options={categoriesList}
      />
    </div>
  )
}
