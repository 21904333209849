import React from 'react'
import { useNavigate } from 'react-router'
import queryString from 'query-string'
import { isMobileDevice } from '../../../../utils/check'
import { REPORTS_COUNT } from '../../ReportPage-constants'
import { useFetchReports } from '../../ReportPage-hooks'
import './Reports.sass'
import { SkeletonBody } from '../../../../components/SkeletonBody'
import { IncorretResult } from '../../../../components/IncorretResult'
import classNames from 'classnames'
import { ReportsWindows } from '../ReportsWindows'
import { ReportsTable } from '../ReportsTable'
import { Button } from '../../../../components/Button'
import { Pagination } from '../../../../components/Pagination'
import { ReportAPIAnswerType } from '../../../../types/report'
import { useURLParams } from '../../../../hooks/useURLParams'

interface ReportsProps {
  className?: string
}

export const Reports: React.FC<ReportsProps> = ({ className }) => {
  const navigate = useNavigate()
  const searchParams = useURLParams()
  const { page } = searchParams
  const currentPage = page ? +page : 1
  const isMobile = isMobileDevice()

  const {
    data: reportsRequestData,
    isLoading,
    isFetching,
    isError,
  } = useFetchReports(currentPage) as {
    data: ReportAPIAnswerType
    isLoading: boolean
    isFetching: boolean
    isError: boolean
  }

  const reportsList = React.useMemo(
    () => reportsRequestData?.items,
    [reportsRequestData]
  )

  const total = reportsRequestData?.total
  const pageCount = React.useMemo(() => {
    return Math.ceil(total / REPORTS_COUNT)
  }, [total])

  const handleChangePage = (page: number) => {
    searchParams.page = (page + 1).toString()
    navigate({ search: queryString.stringify(searchParams) })
  }
  const createNewReport = () => {
    navigate('new')
  }

  if (isLoading) {
    const mode = isMobile === true ? 'windows' : 'table'
    return (
      <SkeletonBody
        mode={mode}
        columnsCount={4}
        actionMode={'all'}
        withFilters={false}
      />
    )
  }
  if (isError) {
    return <IncorretResult />
  }

  const bodyCls = classNames('list-data-wrapper', {
    'list-data-wrapper_fetching': isFetching === true,
    [`${className}`]: className,
  })

  return (
    <div className={bodyCls}>
      {isMobile ? (
        <ReportsWindows reportsList={reportsList} />
      ) : (
        <ReportsTable reportsList={reportsList} />
      )}
      <div className='list-data-wrapper-actions'>
        <Button
          className='list-data-wrapper-actions__btn'
          onClick={createNewReport}
        >
          Запросить отчет
        </Button>
        {pageCount > 1 && (
          <Pagination
            className='list-data-wrapper-actions__pagination'
            activeId={currentPage - 1}
            onChange={handleChangePage}
            count={pageCount}
          />
        )}
      </div>
    </div>
  )
}
