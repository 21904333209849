import React from 'react'
import { SearchModule } from '../../modules/SearchModule'
import { AuthorsModule } from './AuthorsModule'
import './AuthorsPage.sass'

export const AuthorsPage = () => {
  return (
    <div className='section-outer section-authors bottom-indent'>
      <div className='section-inner authors-page'>
        <SearchModule className='authors-page__search' />
        <AuthorsModule />
      </div>
    </div>
  )
}
