import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { MATERIAL_CACHE_PATH } from '../../constants/globalConstants'
import { switchFavouriteRequest } from '../../utils/sharedApi'
import { MaterialDetailType } from '../../utils/types'
import { getMaterialDetailsById } from './DetailsPage-api'

export const useMaterialById = (materialId: number | undefined) => {
  const query = useQuery(
    [MATERIAL_CACHE_PATH, { id: materialId }],
    async () => {
      const materialById = await getMaterialDetailsById(materialId)
      return materialById
    },
    {
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      // staleTime: 100000,
      // refetchInterval: 100000,
      enabled: Boolean(materialId),
      retry: 1,
    }
  )
  return query
}

export const useFavouriteItem = () => {
  const client = useQueryClient()
  const mutation = useMutation((id: number) => switchFavouriteRequest(id), {
    onMutate: async (id) => {
      const oldMaterialInfo: MaterialDetailType | undefined =
        await client.getQueryData([MATERIAL_CACHE_PATH, { id: String(id) }])
      if (oldMaterialInfo === undefined) {
        return
      }
      const updatedMaterialInfo = {
        ...oldMaterialInfo,
        isFavorite: !oldMaterialInfo.isFavorite,
      }
      client.setQueryData(
        [MATERIAL_CACHE_PATH, { id: String(id) }],
        updatedMaterialInfo
      )
      return { oldMaterialInfo }
    },
    onError: (error, values, context?: any) => {
      toast.error('Не удалось добавить в избранное')
    },
    onSettled: async ({ ...props }) => {
      await client.invalidateQueries([MATERIAL_CACHE_PATH])
    },
  })
  return mutation
}
