import { NOTIFICATION_EXPIRED_DAYS } from '../constants/globalConstants'

export const isObjectEmpty = (obj: object) => {
  for (var i in obj) return false
  return true
}

export const isExpiredDateTooClose = (date: string) => {
  const MS_IN_DAYS = 1000 * 60 * 60 * 24
  if (!date) return false
  const nowDate = new Date()
  try {
    const checkDate = new Date(date)
    const diffInDays = (+checkDate - +nowDate) / MS_IN_DAYS
    if (diffInDays < NOTIFICATION_EXPIRED_DAYS) {
      return true
    }
    return false
  } catch {
    return false
  }
}

// ==============================
// Mobile Device Detector
// ==============================

export function isMobileDevice() {
  try {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  } catch (e) {
    return false
  }
}
