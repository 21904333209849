import classNames from 'classnames'
import React from 'react'
import placeholderImg from '../../assets/img/placholder_img.jpg'
import errorImg from '../../assets/img/error_img.jpg'
import './MaterialImg.sass'

interface MaterialImgProps {
  className?: string
  srcPath: string
  onClick?: () => void
  contain?: boolean
  StatComponent?: JSX.Element
}

export const MaterialImg: React.FC<MaterialImgProps> = React.memo(
  ({ className, srcPath, onClick, contain, StatComponent }) => {
    const [imgSrc, setImgSrc] = React.useState(placeholderImg || srcPath)

    const onLoad = React.useCallback(() => {
      setImgSrc(srcPath)
    }, [srcPath])

    const onError = React.useCallback(() => {
      setImgSrc(errorImg || placeholderImg)
    }, [])

    React.useEffect(() => {
      const img = new Image()
      img.src = srcPath as string
      img.addEventListener('load', onLoad)
      img.addEventListener('error', onError)

      return () => {
        img.removeEventListener('load', onLoad)
        img.removeEventListener('error', onError)
      }
    }, [srcPath, onLoad, onError])

    const materialPrfx = 'material-img'
    const imgPrfx = 'material-img__original'
    const divCls = classNames(materialPrfx, {
      [`${className}`]: className,
    })
    const imgCls = classNames(imgPrfx, {
      [`${imgPrfx}_contain`]: contain,
    })

    return (
      <div className={divCls}>
        <img
          src={imgSrc}
          alt='Загруженная фотография'
          className={imgCls}
          onClick={onClick && onClick}
        />
        {StatComponent && StatComponent}
      </div>
    )
  }
)
