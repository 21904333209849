import classNames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'
import { SEARCH_MATERIALS_PATH } from '../../constants/globalConstants'
import { TagsType } from '../../utils/types'
import './MaterialTagsItem.sass'

interface MaterialTagsItemProps {
  className?: string
  tags: TagsType | []
}

export const MaterialTagsItem: React.FC<MaterialTagsItemProps> = ({
  className,
  tags,
}) => {
  const tagsContainerPrfx = 'material-details-tags'
  const tagsContainerCls = classNames(tagsContainerPrfx, {
    [`${className}`]: className,
  })

  return (
    <div className={tagsContainerCls}>
      <div className={`${tagsContainerPrfx}-list`}>
        {tags?.length &&
          tags.map((tag) => {
            const clearTag = tag.replaceAll('#', '')
            return (
              <Link
                key={clearTag}
                to={`${SEARCH_MATERIALS_PATH}?tags=${clearTag}`}
                className={`${tagsContainerPrfx}-list__item`}
              >
                {clearTag}
              </Link>
            )
          })}
      </div>
    </div>
  )
}
