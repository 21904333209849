import classNames from 'classnames'
import React from 'react'
import { Loader } from '../Loader'
import './AutocompleteOptions.sass'

interface AutocompleteOptionsProps {
  className?: string
  options: string[]
  isLoading: boolean
  setValue: (value: string) => void
}

export const AutocompleteOptions: React.FC<AutocompleteOptionsProps> = ({
  className,
  options,
  setValue,
  isLoading,
}) => {
  const windowPrfx = 'autocomplete-options'
  const windowCls = classNames(windowPrfx, {
    [`${className}`]: className,
  })

  return (
    <div className={windowCls} tabIndex={-1}>
      {isLoading && <Loader />}
      {!options.length && !isLoading && (
        <span className={`${windowPrfx}__empty`}>Нет совпадений...</span>
      )}
      {!isLoading && !!options.length && (
        <ul className={`${windowPrfx}-list`} tabIndex={-1}>
          {options.map((option) => (
            <li
              className={`${windowPrfx}-list__item`}
              key={option}
              onClick={() => setValue(option)}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
