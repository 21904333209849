import classNames from 'classnames'
import React from 'react'
import { ru } from 'date-fns/locale'
import ReactDatePicker from 'react-datepicker'
import './DatePickerCustom.sass'

interface DatePickerCustomProps {
  className?: string
  title: string
  name: string
  selected: Date
  errors?: any
  placeholder?: string
  required?: boolean
  onChange: (date: Date) => void
}

export const DatePickerCustom: React.FC<DatePickerCustomProps> = ({
  className,
  title,
  placeholder,
  name,
  selected,
  required,
  errors,
  onChange,
}) => {
  const datePickerPrfx = 'date-picker'
  const datePickerWrapPrfx = `${datePickerPrfx}-wrapper`

  const datePirckerWrapCls = classNames(datePickerWrapPrfx, {
    [`${className}`]: className,
  })
  const datePickerCls = classNames(datePickerPrfx, {
    [`date-picker_error`]: !!errors,
  })
  return (
    <div className={datePirckerWrapCls}>
      <label htmlFor={name}>
        {required && <span>* </span>}
        {title}
      </label>
      <ReactDatePicker
        name={name}
        selected={selected}
        onChange={onChange}
        className={datePickerCls}
        locale={ru}
        placeholderText={placeholder}
        dateFormat='dd.MM.yyyy'
      />
    </div>
  )
}
