import React from 'react'
import queryString from 'query-string'
import { Button } from '../../components/Button'
import { CategoriesAll } from '../../components/CategoriesAll'
import { CategoriesPopular } from '../../components/CategoriesPopular'
import { SEARCH_MATERIALS_PATH } from '../../constants/globalConstants'
import { useCategories } from '../../utils/dataHooks'
import { OptionType } from '../../utils/types'
import './CategoriesModule.sass'
import { usePopularCategories } from './CategoriesModule-hooks'
import { useNavigate } from 'react-router'
import { useURLParams } from '../../hooks/useURLParams'
import { CATEGORY_URL_VALUE } from '../../constants/filterURLNames'

export const CategoriesModule: React.FC = () => {
  const navigate = useNavigate()
  const [categoriesVisible, setCategoriesVisible] = React.useState(false)
  const params = useURLParams()

  const selectedCatogoryId = params[CATEGORY_URL_VALUE]
  const { data: categoriesList, isLoading: isCategoriesLoading } =
    useCategories() as {
      data: OptionType[]
      isLoading: boolean
    }
  const { data: popularCategories } = usePopularCategories() as {
    data: OptionType[]
  }

  const handleButtonClick = () => {
    setCategoriesVisible(!categoriesVisible)
  }

  const handleCategoryClick = (category: OptionType) => {
    if (Number(selectedCatogoryId) !== category.id) {
      const categoryParams = {
        [CATEGORY_URL_VALUE]: category.value!,
      }
      // params[CATEGORY_URL_VALUE] = category.value!

      navigate({
        pathname: SEARCH_MATERIALS_PATH,
        search: queryString.stringify(categoryParams),
      })
    } else {
      delete params[CATEGORY_URL_VALUE]
      navigate({
        pathname: SEARCH_MATERIALS_PATH,
        search: queryString.stringify(params),
      })
    }
  }

  const closeMenu = () => {
    if (categoriesVisible) setCategoriesVisible(false)
  }

  return (
    <div className='section-outer section-categories'>
      <div className='section-inner categories-module'>
        <span className='categories-module__title'>Популярные категории</span>
        <CategoriesAll
          className='categories-module__all'
          categories={categoriesList}
          currentCategoryId={selectedCatogoryId}
          handleCategoryClick={handleCategoryClick}
          isVisible={categoriesVisible}
          closeMenu={closeMenu}
        />
        <CategoriesPopular
          className='categories-module__popular'
          currentCategoryId={selectedCatogoryId}
          handleCategoryClick={handleCategoryClick}
          categories={popularCategories}
        />
        <Button
          className='categories-module__btn'
          type='transparent'
          disabled={isCategoriesLoading}
          onClick={handleButtonClick}
          short
        >
          Смотреть все категории
        </Button>
      </div>
    </div>
  )
}
