import React from 'react'
import { SkeletonBase } from '../SkeletonBase'
import './SkeletonGallery.sass'

interface SkeletonGalleryProps {
  itemsCount: number
}

export const SkeletonGallery: React.FC<SkeletonGalleryProps> = ({
  itemsCount,
}) => {
  const itemsBlock = React.useMemo(
    () => (
      <div className='gallery-grid skeleton-gallery'>
        {[...Array(itemsCount)].map((e, i) => (
          <SkeletonBase
            className='skeleton-gallery__img'
            key={i}
            type='thumbnail'
          />
        ))}
      </div>
    ),
    [itemsCount]
  )
  return itemsBlock
}
