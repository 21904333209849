import classNames from 'classnames'
import React, { useMemo } from 'react'
import queryString from 'query-string'
import { AuthorCard } from '../../../components/AuthorCard'
import { IncorretResult } from '../../../components/IncorretResult'
import { Pagination } from '../../../components/Pagination'
import { SkeletonWindows } from '../../../components/SkeletonWindows'
import { AUTHORS_COUNT } from '../AuthorsPage-constants'
import { useFetchPopularAuthors } from '../AuthorsPage-hooks'
import { AuthorsAPIAnswerType } from '../AuthorsPage-types'
import { useLocation, useNavigate } from 'react-router'
import './AuthorsModule.sass'

export const AuthorsModule = () => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const searchParams = useMemo(
    () => Object.fromEntries(new URLSearchParams(search)),
    [search]
  )
  const { page } = searchParams
  const currentPage = page ? +page : 1

  const {
    data: authorsData,
    isLoading: isAuthorsLoading,
    isFetching: isAuthorsFetching,
    isError: isAuthorsError,
  } = useFetchPopularAuthors(currentPage) as {
    data: AuthorsAPIAnswerType
    isFetching: boolean
    isLoading: boolean
    isError: boolean
  }

  const authorsList = React.useMemo(() => authorsData?.items, [authorsData])
  const total = authorsData?.total

  const pageCount = React.useMemo(() => {
    return Math.ceil(total / AUTHORS_COUNT)
  }, [total])

  const handleChangePage = (page: number) => {
    searchParams.page = (page + 1).toString()
    navigate({ search: queryString.stringify(searchParams) })
  }

  if (isAuthorsLoading) {
    return <SkeletonWindows />
  }
  if (isAuthorsError) {
    return <IncorretResult />
  }

  const bodyCls = classNames('authors-module', {
    'authors-module_fetching': isAuthorsFetching === true,
  })

  return (
    <div className={bodyCls}>
      <div className='authors-module-list'>
        {authorsList.map((author) => (
          <AuthorCard
            className='authors-module-list__item'
            name={author.name}
            downloadCount={author.downloadCount}
            protectedHash={author.protectedHash}
            viewCount={author.viewCount}
            key={author.id}
            id={author.id}
          />
        ))}
      </div>
      <div className='authors-module-actions'>
        {pageCount > 1 && (
          <Pagination
            className='authors-module-actions__pagination'
            activeId={currentPage - 1}
            onChange={handleChangePage}
            count={pageCount}
          />
        )}
      </div>
    </div>
  )
}
