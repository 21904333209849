import classNames from 'classnames'
import React from 'react'
import { useSelector } from 'react-redux'
import { EmptyResult } from '../../../../components/EmptyResult'
import { Gallery } from '../../../../modules/Gallery'
import { IncorretResult } from '../../../../components/IncorretResult'
import { SkeletonMainGallery } from '../../../../components/SkeletonMainGallery'
import { MaterialsResponseType } from '../../../../utils/types'
import { useFavouriteItem, useFetchGuestMaterials } from './GalleryModule-hooks'
import './GalleryModule.sass'
import { RootState } from '../../../../reducers'

interface GalleryModuleProps {
  className?: string
}

export const GalleryModule: React.FC<GalleryModuleProps> = ({ className }) => {
  const isAuth = useSelector((state: RootState) => state.auth.isAuth)

  const {
    data: materialsRequestData,
    isFetching,
    isLoading,
    isError,
  } = useFetchGuestMaterials(isAuth) as {
    data: MaterialsResponseType
    isLoading: boolean
    isFetching: boolean
    isError: boolean
  }

  const { mutateAsync: favouriteMaterial } = useFavouriteItem(isAuth)

  const handleFavouriteClick = (id: number) => {
    favouriteMaterial(id)
  }

  const materials = React.useMemo(
    () => materialsRequestData?.items,
    [materialsRequestData]
  )

  const wrapperPrfx = 'main-gallery-wrapper'
  const galleryPrfx = 'main-gallery'
  const wrapperCls = classNames(wrapperPrfx, {
    [`${className}`]: className,
  })
  const galleryCls = classNames(galleryPrfx, {
    [`${galleryPrfx}_fetching`]: isFetching === true,
  })

  if (isLoading) {
    return <SkeletonMainGallery />
  }
  if (isError) {
    return <IncorretResult />
  }
  if (materials?.length === 0) {
    return <EmptyResult />
  }

  return (
    <div className={wrapperCls}>
      <h3 className={`${wrapperPrfx}__title`}>
        Пользователи "Media4Media" выбирают
      </h3>
      <div className={galleryCls}>
        <Gallery
          materials={materials}
          className={`${galleryPrfx}__gallery`}
          onFavouriteClick={handleFavouriteClick}
        />
      </div>
    </div>
  )
}
