import React from 'react'
import { SkeletonBase } from '../SkeletonBase'
import './SkeletonMaterials.sass'

export const SkeletonMaterials = () => {
  return (
    <div className='skeleton-materials'>
      <div className='skeleton-materials-gallery'>
        <SkeletonBase
          type='thumbnail'
          className='skeleton-materials-gallery__item'
        />
        <SkeletonBase
          type='thumbnail'
          className='skeleton-materials-gallery__item'
        />
        <SkeletonBase
          type='thumbnail'
          className='skeleton-materials-gallery__item'
        />
        <SkeletonBase
          type='thumbnail'
          className='skeleton-materials-gallery__item'
        />
        <SkeletonBase
          type='thumbnail'
          className='skeleton-materials-gallery__item'
        />
        <SkeletonBase
          type='thumbnail'
          className='skeleton-materials-gallery__item'
        />
      </div>
    </div>
  )
}
