import React from 'react'
import { SkeletonBase } from '../SkeletonBase'
import './SkeletonTable.sass'

interface SkeletonTableProps {
  columnCount: number
}

export const SkeletonTable: React.FC<SkeletonTableProps> = ({
  columnCount,
}) => {
  const columns = React.useMemo(
    () =>
      [...Array(columnCount)].map((e, i) => (
        <SkeletonBase
          className='skeleton-table-row__item'
          type='text'
          key={i}
        />
      )),
    [columnCount]
  )
  return (
    <div className='skeleton-table'>
      <div className='skeleton-table-header'>{columns}</div>
      <div className='skeleton-table-row'>{columns}</div>
      <div className='skeleton-table-row'>{columns}</div>
      <div className='skeleton-table-row'>{columns}</div>
      <div className='skeleton-table-row'>{columns}</div>
      <div className='skeleton-table-row'>{columns}</div>
      <div className='skeleton-table-row'>{columns}</div>
      <div className='skeleton-table-row'>{columns}</div>
      <div className='skeleton-table-row'>{columns}</div>
      <div className='skeleton-table-row'>{columns}</div>
      <div className='skeleton-table-row'>{columns}</div>
      <div className='skeleton-table-row'>{columns}</div>
      <div className='skeleton-table-row'>{columns}</div>
    </div>
  )
}
