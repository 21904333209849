import { REGISTRATION_API_PATH } from '../../constants/globalConstants'
import { instance } from '../../utils/coreAPI'
import { RegisterFieldsType } from './RegisterForm-types'

export const signUpCall = async (credencials: RegisterFieldsType) => {
  try {
    const response = await instance.post(REGISTRATION_API_PATH, credencials)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error ?? 'Проблема соединения с сервером')
  }
}
