import classNames from 'classnames'
import React from 'react'
import { REPORT_STATUS_ENUM } from '../../types/enums'
import { getReportTranslatedStatus } from '../../utils/translate'
import { getNormalizeDate, getPathToReportFromHash } from '../../utils/utils'
import './ReportWindow.sass'

interface ReportWindowProps {
  className?: string
  hash: string
  status: REPORT_STATUS_ENUM
  startDate: string
  periodStart: string
  periodFinish: string
}

export const ReportWindow: React.FC<ReportWindowProps> = React.memo(
  ({ className, hash, status, startDate, periodStart, periodFinish }) => {
    const windowPrfx = 'report-window'
    const windowCls = classNames(windowPrfx, {
      [`${className}`]: className,
      [`${windowPrfx}_deny`]: status !== REPORT_STATUS_ENUM.DONE,
    })

    const statusPrfx = 'report-window__status'
    const statusCls = classNames(statusPrfx, {
      [`${statusPrfx}_accept`]: status === REPORT_STATUS_ENUM.DONE,
      [`${statusPrfx}_deny`]:
        status === REPORT_STATUS_ENUM.ERROR ||
        status === REPORT_STATUS_ENUM.PROCESSING,
    })

    const startDateValue = getNormalizeDate(startDate)
    const periodStartValue = getNormalizeDate(periodStart)
    const periodEndValue = getNormalizeDate(periodFinish)
    const statusValue = getReportTranslatedStatus(status)
    const linkPath = getPathToReportFromHash(hash)

    return (
      <a className={windowCls} href={linkPath} target='_blank' rel='noreferrer'>
        <div className={`${windowPrfx}__date`}>{startDateValue}</div>
        <div className={`${windowPrfx}__interval`}>
          {periodStartValue} - {periodEndValue}
        </div>
        <div className={statusCls}>{statusValue}</div>
      </a>
    )
  }
)
