import classNames from 'classnames'
import React from 'react'
import { AiFillStar, AiOutlineStar } from 'react-icons/ai'
import { Button } from '../Button'
import './ImgFavouriteWrapper.sass'

interface ImgFavouriteWrapperProps {
  onFavouriteClick?: (id: number) => void
  isFavourite?: boolean
}

export const ImgFavouriteWrapper: React.FC<ImgFavouriteWrapperProps> = ({
  onFavouriteClick,
  isFavourite,
}) => {
  const divPrfx = 'img-favourite-wrapper'
  const divCls = classNames(divPrfx, 'cls_918437')
  return (
    <div className={divCls}>
      {isFavourite !== undefined && (
        <Button
          type='transparent'
          className={`${divPrfx}__favourite`}
          short
          onClick={onFavouriteClick}
        >
          <span className={`${divPrfx}__favourite-title`}>
            {isFavourite ? <AiFillStar /> : <AiOutlineStar />}
          </span>
        </Button>
      )}
    </div>
  )
}
