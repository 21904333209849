import classNames from 'classnames'
import React from 'react'
import './SkeletonBase.sass'

type SkeletonElementType = 'title' | 'img' | 'text' | 'thumbnail'

interface SkeletonBaseProps {
  className?: string
  type: SkeletonElementType
}

export const SkeletonBase: React.FC<SkeletonBaseProps> = ({
  className,
  type,
}) => {
  const skeletonPrfx = 'skeleton-element'
  const skeletonCls = classNames(skeletonPrfx, {
    [`${skeletonPrfx}_${type}`]: type,
    [`${className}`]: className,
  })
  return <div className={skeletonCls}></div>
}
