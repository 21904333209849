import React from 'react'
import './InformationPage.sass'

export const InformationPage = () => {
  return (
    <div className='section-outer section-information bottom-indent'>
      <div className='section-inner information-page'>
        <h1 className='information-page__title'>Информация для покупателей</h1>
        <div className='information-page__text'>
          <p className='information-page__paragraph'>
            Фотобанк media4media гарантирует, что является владельцем всех прав
            и обладает всеми надлежащими полномочиями на фотографии, которые
            размещены на данном ресурсе, и вправе предоставлять к ним доступ и
            право использования в соответствии с условиями редакционной
            лицензии. Предоставляемый доступ к фотографиям в неизмененном виде
            не содержит нарушений прав интеллектуальной собственности, прав на
            публичное исполнение и прав на невмешательство в личную жизнь
            какой-либо третьей стороны.
          </p>
          <p className='information-page__paragraph'>
            В соответствии с условиями редакционной лицензии, фотобанк
            предоставляет на договорной основе не подлежащее передаче любым
            другим третьим лицам право без дополнительных авторских отчислений
            на неисключительной основе осуществлять использование,
            воспроизведение, модификацию, публикацию и отображение загруженных
            фотографий на территории любой страны мира.
          </p>
          <p className='information-page__paragraph'>
            Фотографии, представленные на этом ресурсе, могут быть использованы
            в редакционных целях и не могут быть использованы в коммерческих,
            рекламных или маркетинговых целях.
          </p>
          <p className='information-page__paragraph'>
            Фотографии, используемые в редакционном контексте как в
            традиционных, так и электронных печатных материалах, на вебсайтах, в
            блогах, в телевизионном вещании, онлайн-видеороликах должны
            содержать отметку об авторском праве в соответствии с условиями
            договора.
          </p>
          Фотографии, представленные на этом ресурсе,
          <span className='information-page__text-medium'>
            не могут быть использованы
          </span>{' '}
          каким-либо образом (отдельно или совместно с другими фотографиями и в
          другом контексте), для следующих целей:
          <ul className={`information-page__list`}>
            <li className={`information-page__list-item`}>
              порнографических, оскорбительных, расистских, этнически или
              культурно оскорбительных, непристойных или неприличных, сексуально
              откровенных, аморальных, дискредитирующих, угрожающих
              неприкосновенности частной жизни или незаконных;
            </li>
            <li className={`information-page__list-item`}>
              поддерживающих насилие или террористические акты;
            </li>
            <li className={`information-page__list-item`}>
              информирования о развлекательных и увеселительных услугах или мест
              для развлечений, табачных изделий, медицинских товаров для лечения
              проблем здоровья, связанных с половыми проблемами, психическим
              состоянием или терминальной болезнью;
            </li>
            <li className={`information-page__list-item`}>
              информирования о сайтах знакомств или приложениях, являющихся
              дискриминационным по отношению к расе, полу, религии, вере или
              сексуальная ориентации.
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
