import React from 'react'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import Select from 'react-select'
import { RegisterFieldsType } from './RegisterForm-types'
import { phoneRe } from '../../utils/regulars'
import { Button } from '../../components/Button'
import { InputB } from '../../components/Input/InputB'
import { InputPhone } from '../../components/InputPhone'
import { RoleOptions } from './RegisterForm-constants'
import { ROLE_CONSUMER } from '../../constants/roles'
import { useSignUp } from './RegisterForm-hooks'

export const RegisterForm = () => {
  const {
    mutate: signup,
    isLoading,
    error,
  } = useSignUp() as {
    mutate: (fields: RegisterFieldsType) => void
    isLoading: boolean
    error: Error
  }
  const initialValues = {
    userType: ROLE_CONSUMER,
    login: '',
    password: '',
    name: '',
    email: '',
    phone: '',
  }
  const validationSchema = Yup.object({
    userType: Yup.string().required('Обязательно'),
    login: Yup.string().required('Обязательно'),
    password: Yup.string().required('Обязательно'),
    name: Yup.string().required('Обязательно'),
    email: Yup.string().email().required('Обязательно'),
    phone: Yup.string()
      .matches(phoneRe, 'Неверный формат номера')
      .required('Обязательно'),
  })
  const onSubmit = async (values: RegisterFieldsType) => {
    const objectForSignUp = {
      ...values,
      phone: values.phone
        .replace(/\)/g, '')
        .replace(/\(/g, '')
        .replace(/-/g, '')
        .replace(/ /g, ''),
    }
    signup(objectForSignUp)
  }

  return (
    <div className='auth-module'>
      <h1 className='auth-module__title'>Регистрация</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={true}
        validateOnMount={true}
      >
        {(formik) => (
          <>
            <Form className='auth-module-form'>
              <InputB
                id='login'
                type='text'
                placeholder='Введите логин'
                className='auth-module-form__control'
                value={formik.values['login']}
                onChange={formik.handleChange}
              />
              <InputB
                id='password'
                type='password'
                placeholder='Введите пароль'
                className='auth-module-form__control'
                value={formik.values['password']}
                onChange={formik.handleChange}
              />
              <InputB
                id='name'
                type='text'
                placeholder='Введите имя'
                className='auth-module-form__control'
                value={formik.values['name']}
                onChange={formik.handleChange}
              />
              <InputB
                id='email'
                type='email'
                placeholder='Введите почту'
                className='auth-module-form__control'
                value={formik.values['email']}
                onChange={formik.handleChange}
              />
              <InputPhone
                id='phone'
                value={formik.values['phone']}
                onChange={formik.handleChange}
                placeholder='Номер телефона'
                className='auth-module-form__control'
              />
              <Select
                {...formik.getFieldMeta('userType')}
                {...formik.getFieldProps('userType')}
                {...formik.getFieldHelpers('userType')}
                className='react-select-container auth-module-form__control'
                classNamePrefix='react-select'
                options={RoleOptions}
                value={RoleOptions.find(
                  (option) =>
                    option.value === formik.getFieldProps('userType').value
                )}
                onChange={(option) =>
                  formik.setFieldValue(
                    formik.getFieldProps('userType').name,
                    (option as any).value
                  )
                }
              />
              <Button
                type='primary'
                htmlType='submit'
                className='auth-module-form__btn'
                loading={isLoading}
                fullSize
                disabled={!formik.isValid}
              >
                Зарегистрироваться
              </Button>
            </Form>
          </>
        )}
      </Formik>
      {error && (
        <span className='auth-module__error'>{error?.message || error}</span>
      )}
    </div>
  )
}
