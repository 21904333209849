import queryString from 'query-string'
import { useCallback } from 'react'
import { useNavigate } from 'react-router'
import { GET_ENUMS, GET_PARAMS } from '../constants/modals'
import { useURLParams } from './useURLParams'

export const useAuthModal = () => {
  const params = useURLParams()
  const navigate = useNavigate()
  return useCallback(() => {
    params[GET_PARAMS.popup] = GET_ENUMS.popup.signin
    navigate({ search: queryString.stringify(params) })
  }, [params, navigate])
}
