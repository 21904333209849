import classNames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'
import { SEARCH_MATERIALS_PATH } from '../../constants/globalConstants'
import { ContributorType } from '../../utils/types'
import { ReactComponent as Camera } from '../../assets/img/photo.svg'
import './MaterialDetailAuthor.sass'

interface MaterialDetailAuthorProps {
  className?: string
  contributor: ContributorType
}

export const MaterialDetailAuthor: React.FC<MaterialDetailAuthorProps> = ({
  className,
  contributor,
}) => {
  const detailPrfx = 'material-details-author'
  const detailCls = classNames(detailPrfx, {
    [`${className}`]: className,
  })

  if (!contributor?.id) {
    return null
  }

  return (
    <div className={`${detailCls}`}>
      <Link
        to={`${SEARCH_MATERIALS_PATH}?contributorId=${contributor.id}`}
        className={`${detailPrfx}__link`}
      >
        <span className={`${detailPrfx}__img`}>
          <Camera />
        </span>
        <span className={`${detailPrfx}__text`}>{contributor.name}</span>
      </Link>
    </div>
  )
}
