import { instance } from '../../../../utils/coreAPI'
import { MATERIAL_API_PATH } from '../../../../constants/globalConstants'
import axios from 'axios'

export const purchaseItemRequest = async (id: number) => {
  try {
    const response = await instance.post(`${MATERIAL_API_PATH}/${id}/purchase`)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const downloadFileRequest = async (filePath: string) => {
  try {
    const response = await axios.get(filePath, {
      responseType: 'blob',
    })
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
