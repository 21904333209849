import classNames from 'classnames'
import React from 'react'
import { useHorizontalScroll } from '../../utils/hooks'
import { OptionType } from '../../utils/types'
import './CategoriesPopular.sass'

interface CategoriesPopularProps {
  className?: string
  categories: OptionType[] | null
  currentCategoryId: string | undefined
  handleCategoryClick: (category: OptionType) => void
}

export const CategoriesPopular: React.FC<CategoriesPopularProps> = ({
  className,
  categories,
  currentCategoryId,
  handleCategoryClick,
}) => {
  const ulPrfx = 'categories-popular-list'
  const ulCls = classNames(ulPrfx, {
    [`${className}`]: className,
  })

  const getCategoryCls = (id: string | null) => {
    if (`${id}` === currentCategoryId) {
      return `${ulPrfx}__item ${ulPrfx}__item_active`
    } else {
      return `${ulPrfx}__item`
    }
  }

  const ulRef = useHorizontalScroll()

  if (!categories) {
    return null
  }

  return (
    <ul className={ulCls} ref={ulRef}>
      {categories.map((category) => (
        <li
          className={getCategoryCls(category.value)}
          onClick={() => handleCategoryClick(category)}
          key={category.id}
        >
          {category.label}
        </li>
      ))}
    </ul>
  )
}
