import { useMutation } from 'react-query'
import { useNavigate } from 'react-router'
import { REGISTRATION_COMPLETE_PATH } from '../../constants/globalConstants'
import { signUpCall } from './RegisterForm-api'
import { RegisterFieldsType } from './RegisterForm-types'

export const useSignUp = () => {
  const navigate = useNavigate()
  const mutation = useMutation(
    (credencials: RegisterFieldsType) => signUpCall(credencials),
    {
      onSuccess: async (data, variables) => {
        navigate(REGISTRATION_COMPLETE_PATH, {
          replace: true,
        })
      },
    }
  )
  return mutation
}
