import classNames from 'classnames'
import React from 'react'
import queryString from 'query-string'
import { Button } from '../../components/Button'
import { FilterAuthor } from '../FilterAuthor'
import { FilterCategory } from '../FilterCategory'
import { FilterDate } from '../FilterDate'
import { FilterSort } from '../FilterSort'
import './FilterModule.sass'
import { FilterIcon } from '../../components/FilterIcon'
import { useURLParams } from '../../hooks/useURLParams'
import { useNavigate } from 'react-router'
import {
  CATEGORY_URL_VALUE,
  CONTRIBUTOR_URL_VALUE,
  INTERVAL_URL_VALUE,
  PAGE_URL_VALUE,
  SORT_URL_VALUE,
} from '../../constants/filterURLNames'

interface FilterModuleProps {
  className?: string
  title?: string | string[] | null
  withAuthor?: boolean
  withSort?: boolean
  withDate?: boolean
  withCategory?: boolean
}

export const FilterModule: React.FC<FilterModuleProps> = ({
  className,
  title,
  withAuthor,
  withCategory,
  withDate,
  withSort,
}) => {
  const [isFilterShow, setIsFilterShow] = React.useState(false)

  const filterPrfx = 'filters-module'
  const filterCls = classNames(filterPrfx, {
    [`${className}`]: className,
  })

  const settingsPrfx = 'filters-module-settings'
  const settingsCls = classNames(settingsPrfx, {
    [`${settingsPrfx}_hidden`]: isFilterShow === false,
  })

  const navigate = useNavigate()
  const params = useURLParams()

  const handleExpandFilters = () => {
    setIsFilterShow(!isFilterShow)
  }

  const handleResetClick = () => {
    if (params) {
      withSort && delete params[SORT_URL_VALUE]
      withDate && delete params[INTERVAL_URL_VALUE]
      withAuthor && delete params[CONTRIBUTOR_URL_VALUE]
      withCategory && delete params[CATEGORY_URL_VALUE]
      params[PAGE_URL_VALUE] = '1'
    }
    navigate({ search: queryString.stringify(params) })
  }
  return (
    <div className={filterCls}>
      <div className={`${filterPrfx}-actions`}>
        {title && (
          <span className={`${filterPrfx}-actions__title`}>{title}</span>
        )}
        <FilterIcon
          className={`${filterPrfx}-actions__item`}
          isActive={isFilterShow}
          onClick={handleExpandFilters}
        />
      </div>

      <div className={settingsCls}>
        {withSort && (
          <FilterSort
            navigate={navigate}
            params={params}
            className={`${settingsPrfx}__control`}
          />
        )}
        {withDate && (
          <FilterDate
            navigate={navigate}
            params={params}
            className={`${settingsPrfx}__control`}
          />
        )}
        {withAuthor && (
          <FilterAuthor
            navigate={navigate}
            params={params}
            className={`${settingsPrfx}__control`}
          />
        )}
        {withCategory && (
          <FilterCategory
            navigate={navigate}
            params={params}
            className={`${settingsPrfx}__control`}
          />
        )}
        <Button
          className={`${settingsPrfx}__btn`}
          onClick={handleResetClick}
          type='secondary'
          short
        >
          Сбросить фильтры
        </Button>
      </div>
    </div>
  )
}
