import { combineReducers } from 'redux'
import { BreadCrumbsReducer } from './modules/BreadCrumbs/BreadCrumbs-reducer'
import { AuthReducer } from './modules/AuthForm/AuthForm-reducers'

export const reducers = combineReducers({
  auth: AuthReducer,
  breadCrumbs: BreadCrumbsReducer,
})

export type RootState = ReturnType<typeof reducers>
