export const CustomInputPrefix = 'custom-input'
export const CustomCheckboxPrefix = 'custom-checkbox'

export const ControlWrapperPrefixCls = 'control-wrapper'
export const InputPasswordPrefixCls = 'password-wrapper__input'
export const InputEditablePrefixCls = 'editable-wrapper__input'
export const InputPrefixCls = 'control-wrapper__input'
export const SwitchPrefixCls = 'control-wrapper__switch'
export const CheckboxPrefixCls = 'control-wrapper__checkbox'
export const RadioPrefixCls = 'control-wrapper__radio'
