import { ROLE_CONSUMER, ROLE_CONTRIBUTOR } from '../../constants/roles'

export const RoleOptions = [
  {
    label: 'Поставщик',
    value: ROLE_CONTRIBUTOR,
  },
  {
    label: 'Потребитель',
    value: ROLE_CONSUMER,
  },
]
