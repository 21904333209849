import React from 'react'
import classNames from 'classnames'
import { AuthType } from './AuthModule-types'
import { AuthForm } from '../AuthForm'
import { RegisterForm } from '../RegisterForm'
import './AuthModule.sass'
import { Popup } from '../../components/Popup'

export const AuthModule = () => {
  const [mode, setMode] = React.useState<AuthType>('login')
  const handleSelectLogin = () => {
    setMode('login')
  }
  const handleSelectRegster = () => {
    setMode('register')
  }

  const authWrapperPrfx = 'auth-module-wrapper'
  const loginPrfx = 'auth-module-wrapper-role__login'
  const registerPrfx = 'auth-module-wrapper-role__register'
  const loginCls = classNames(loginPrfx, {
    [`${loginPrfx}_active`]: mode === 'login',
  })
  const registerCls = classNames(registerPrfx, {
    [`${registerPrfx}_active`]: mode === 'register',
  })

  return (
    <Popup>
      <div className={authWrapperPrfx}>
        <div className={`${authWrapperPrfx}-role`}>
          <div className={registerCls} onClick={handleSelectRegster}>
            Зарегистрироваться
          </div>
          <div className={loginCls} onClick={handleSelectLogin}>
            Войти
          </div>
        </div>
        <div className={`${authWrapperPrfx}-form`}>
          {mode === 'login' && <AuthForm />}
          {mode === 'register' && <RegisterForm />}
        </div>
      </div>
    </Popup>
  )
}
