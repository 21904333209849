import classNames from 'classnames'
import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import Close from '../../assets/img/close.svg'
import { Button } from '../../components/Button'
import { Divider } from '../../components/Divider'
import { Popup } from '../../components/Popup'
import {
  ABOUT_PATH,
  AUTHORS_PATH,
  CONTACTS_PATH,
  FAVOURITE_PATH,
  INFORMATION_PATH,
  PURCHASED_PATH,
  REPORT_PATH,
} from '../../constants/globalConstants'
import { RootState } from '../../reducers'
import { StatisticType } from '../../types/statistic'
import { useLogout } from '../AuthForm/AuthForm-hooks'
import {
  useFetchProfileInfo,
  useFetchProfileStatistic,
} from './UserProfile-hooks'
import { UserSelfType } from './UserProfile-types'
import './UserProfile.sass'

export const UserProfile = ({ isOpened = false }: { isOpened?: boolean }) => {
  const navigate = useNavigate()
  const { mutate: logout } = useLogout() as {
    mutate: () => void
  }
  const isAuth = useSelector((state: RootState) => state.auth.isAuth)
  const userName = useSelector((state: RootState) => state.auth.username)
  const profilePrfx = 'user-profile'
  const profileCls = classNames(`${profilePrfx}`, {})
  const profileContentPrfx = `${profilePrfx}-content`

  const { data } = useFetchProfileInfo(isAuth) as {
    data: UserSelfType
  }

  const { data: statisticData } = useFetchProfileStatistic(isAuth) as {
    data: StatisticType
  }

  const greatText = userName ? `Здравствуйте, ${userName}!` : 'Здравствуйте!'
  const downloadLimit = data?.limit ? data?.limit - data?.purchasedMonth : 0
  const downloadCount = statisticData?.total?.purchased

  const closeProfileWindow = () => navigate(-1)

  const linkCls = `${profileContentPrfx}__link pb-link`
  const linkMobileCls = `${linkCls} ${profileContentPrfx}__link_mobile`
  const linkActiveCls = `${linkCls} pb-link_active`
  const linkActiveMobileCls = `${linkMobileCls} pb-link_active`

  return (
    <Popup>
      <div className={profileCls}>
        <div className={`${profilePrfx}__close`} onClick={closeProfileWindow}>
          <img src={Close} alt='Закрыть панель' />
        </div>
        <div className={`${profileContentPrfx}`}>
          <h2 className={`${profileContentPrfx}__name`}>{greatText}</h2>
          <Divider />
          <NavLink
            to={PURCHASED_PATH}
            className={(props) => {
              return props.isActive ? linkActiveCls : linkCls
            }}
          >
            Купленные материалы
          </NavLink>
          <NavLink
            to={FAVOURITE_PATH}
            className={(props) => {
              return props.isActive ? linkActiveCls : linkCls
            }}
          >
            Избранное
          </NavLink>
          <NavLink
            className={(props) => {
              return props.isActive ? linkActiveMobileCls : linkMobileCls
            }}
            to={AUTHORS_PATH}
          >
            Авторы
          </NavLink>

          <NavLink
            className={(props) => {
              return props.isActive ? linkActiveMobileCls : linkMobileCls
            }}
            to={ABOUT_PATH}
          >
            О нас
          </NavLink>

          <NavLink
            className={(props) => {
              return props.isActive ? linkActiveMobileCls : linkMobileCls
            }}
            to={CONTACTS_PATH}
          >
            Контакты
          </NavLink>

          <NavLink
            className={(props) => {
              return props.isActive ? linkActiveMobileCls : linkMobileCls
            }}
            to={INFORMATION_PATH}
          >
            Информация
          </NavLink>

          <NavLink
            className={(props) => {
              return props.isActive ? linkActiveCls : linkCls
            }}
            to={REPORT_PATH}
          >
            Отчеты
          </NavLink>

          <span className={linkCls}>
            Оставшийся лимит скачиваний: <span>{downloadLimit}</span>
          </span>

          <span className={linkCls}>
            Всего загружено: <span>{downloadCount}</span>
          </span>

          <Divider />
          <Button className={`${profileContentPrfx}__btn`} onClick={logout}>
            Выйти
          </Button>
        </div>
      </div>
    </Popup>
  )
}
