import classNames from 'classnames'
import React from 'react'
import './Loader.sass'

interface LoaderProps {
  className?: string
  position?: 'left' | 'right' | 'center'
}

export const Loader: React.FC<LoaderProps> = ({
  className,
  position = 'center',
}) => {
  const loaderPrfx = 'loader-wrapper'
  const loaderCls = classNames(loaderPrfx, {
    [`${className}`]: className,
    [`${loaderPrfx}_${position}`]: position,
  })
  return (
    <div className={loaderCls}>
      <div className='lds-ellipsis'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}
