import { REPORT_API_PATH } from '../../constants/globalConstants'
import { instance } from '../../utils/coreAPI'
import { REPORTS_COUNT } from './ReportPage-constants'

export const getListReportsCall = async (page: number) => {
  try {
    const response = await instance.get(`${REPORT_API_PATH}`, {
      params: {
        page,
        size: REPORTS_COUNT,
      },
    })
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const reportGenerateCall = async (
  startDate: string,
  endDate: string
) => {
  try {
    const response = await instance.post(`${REPORT_API_PATH}/consumer`, {
      startDate,
      endDate,
    })
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
