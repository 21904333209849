import { filterDateOptions } from './FilterDate-constants'

export const getDateOption = (value: string | string[] | null) => {
  if (!value) return filterDateOptions[0]
  let currentValue = '-1'
  typeof value === 'object' ? (currentValue = value[0]) : (currentValue = value)
  const dateOption = filterDateOptions.filter(
    (dateObject) => dateObject.value === currentValue
  )
  return dateOption[0] ?? filterDateOptions[0]
}
