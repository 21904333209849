import React from 'react'
import { Reports } from './modules/Reports'
import './ReportPage.sass'

export const ReportPage = () => {
  return (
    <div className='section-outer section-authors bottom-indent'>
      <div className='section-inner authors-page'>
        <Reports className='authors-page__search' />
      </div>
    </div>
  )
}
