import { sortOptions } from './FilterSort-constants'

export const getSortOption = (value: string | string[] | null) => {
  if (!value) return sortOptions[0]
  let currentValue = '-1'
  typeof value === 'object' ? (currentValue = value[0]) : (currentValue = value)
  const sortOption = sortOptions.filter(
    (sortObject) => sortObject.value === currentValue
  )
  return sortOption[0] ?? sortOptions[0]
}
