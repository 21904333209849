import classNames from 'classnames'
import React from 'react'
import Select from 'react-select'
import queryString from 'query-string'
import { getSortOption } from './FilterSort-utils'
import { sortOptions } from './FilterSort-constants'
import { NavigateFunction } from 'react-router'
import { PAGE_URL_VALUE, SORT_URL_VALUE } from '../../constants/filterURLNames'

interface FilterSortProps {
  className?: string
  navigate: NavigateFunction
  params: { [k: string]: string }
}

export const FilterSort: React.FC<FilterSortProps> = ({
  className,
  navigate,
  params,
}) => {
  const filterPrfx = 'filter-param-container'
  const filterCls = classNames(filterPrfx, {
    [`${className}`]: className,
  })
  const sortQuery = params[SORT_URL_VALUE]

  const [sortFilter, setSortFilter] = React.useState(getSortOption(sortQuery))

  React.useEffect(() => {
    setSortFilter(getSortOption(sortQuery))
  }, [sortQuery])

  const handleSortChange = (option: any) => {
    setSortFilter(getSortOption(option.value))
    params[SORT_URL_VALUE] = option.value
    params[PAGE_URL_VALUE] = '1'
    navigate({ search: queryString.stringify(params) })
  }

  return (
    <div className={filterCls}>
      <label className={`${filterPrfx}__label`} htmlFor='sort'>
        Сортировка
      </label>
      <Select
        id='sort'
        classNamePrefix='react-select'
        className='react-select-container'
        value={sortFilter}
        onChange={handleSortChange}
        options={sortOptions}
      />
    </div>
  )
}
