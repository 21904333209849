import React from 'react'
import { useSelector } from 'react-redux'
import { ImgFavouriteWrapper } from '../../components/ImgFavouriteWrapper'
import { IncorretResult } from '../../components/IncorretResult'
import { MaterialImg } from '../../components/MaterialImg'
import { SkeletonDetailsPage } from '../../components/SkeletonDetailsPage'
import { SearchModule } from '../../modules/SearchModule'
import { isObjectEmpty } from '../../utils/check'
import { MaterialDetailType } from '../../utils/types'
import { getProtectedPathFromHash } from '../../utils/utils'
import { useFavouriteItem, useMaterialById } from './DetailsPage-hooks'
import './DetailsPage.sass'
import { GuestMaterialActionModule } from './modules/GuestMaterialActionModule'
import { MaterialInfoModule } from './modules/MaterialInfoModule'
import { UserMaterialActionModule } from './modules/UserMaterialActionModule'
import Cookies from 'universal-cookie'
import { RootState } from '../../reducers'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router'
import { ROLE_CONSUMER } from '../../constants/roles'

export const DetailsPage = () => {
  const { id } = useParams<'id'>()
  const navigate = useNavigate()
  const cookies = new Cookies()
  const isAuth = useSelector((state: RootState) => state.auth.isAuth)
  const isConsumer = useSelector(
    (state: RootState) => state.auth.permissions === ROLE_CONSUMER
  )
  const isAuthConsumer = isAuth && isConsumer

  const isModerationVisible = !!id && !!cookies.get('admin')

  const {
    data: materialDetails,
    isLoading,
    isError,
    refetch,
  } = useMaterialById(+id!) as {
    data: MaterialDetailType
    isLoading: boolean
    isError: boolean
    refetch: any
  }

  const { mutateAsync: favouriteMaterial } = useFavouriteItem() as {
    mutateAsync: (id: number) => Promise<any>
    isLoading: boolean
  }

  const handleFavourite = () => {
    if (id) {
      favouriteMaterial(+id!)
    }
  }

  React.useEffect(() => {
    refetch()
  }, [isAuth, refetch])

  const materialPath = React.useMemo(() => {
    return getProtectedPathFromHash(materialDetails?.protectedHash)
  }, [materialDetails])

  if (isLoading) {
    return (
      <div className='section-outer bottom-indent'>
        <div className='section-inner'>
          <SkeletonDetailsPage />
        </div>
      </div>
    )
  }

  if (isObjectEmpty(materialDetails) || isError) {
    return <IncorretResult />
  }

  const handlePhotoClick = () => {
    navigate('view')
  }

  return (
    <div className='section-outer section-details bottom-indent'>
      <div className='section-inner details-page'>
        <SearchModule className='details-page__search' />
        <div className='details-page-info'>
          {isAuthConsumer ? (
            <UserMaterialActionModule
              className='details-page-info__download'
              id={materialDetails?.id}
              isPurchased={materialDetails?.isPurchased}
              isFavourite={materialDetails?.isFavorite}
              rawHash={materialDetails?.rawHash}
              isModerationVisible={isModerationVisible}
            />
          ) : (
            <GuestMaterialActionModule
              className='details-page-info__download'
              isModerationVisible={isModerationVisible}
              id={+id!}
            />
          )}
          <MaterialInfoModule
            className='details-page-info__sub'
            info={materialDetails}
          />
          <MaterialImg
            srcPath={materialPath}
            className='details-page-info__img'
            onClick={handlePhotoClick}
            contain
            StatComponent={
              <ImgFavouriteWrapper
                onFavouriteClick={handleFavourite}
                isFavourite={materialDetails?.isFavorite}
              />
            }
          />
        </div>
      </div>
    </div>
  )
}
