import { MATERIAL_NEWS_API_PATH } from '../../../../constants/globalConstants'
import { instance } from '../../../../utils/coreAPI'
import { GALLERY_SIZE } from './NewsModule-constants'

export const getNews = async () => {
  try {
    const response = await instance.get(MATERIAL_NEWS_API_PATH, {
      params: {
        count: GALLERY_SIZE,
      },
    })
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
