import classNames from 'classnames'
import React from 'react'
import { MATERIALS_PATH } from '../../constants/globalConstants'
import { MaterialsPreviewType } from '../../utils/types'
import { getProtectedPathFromHash } from '../../utils/utils'
import { ImgStatWrapper } from '../../components/ImgStatWrapper'
import { MaterialImg } from '../../components/MaterialImg'
// import { useFavouriteItem } from './Gallery-hooks'
import './Gallery.sass'

interface GalleryProps {
  className?: string
  materials: MaterialsPreviewType
  onFavouriteClick: (id: number) => void
}

export const Gallery: React.FC<GalleryProps> = ({
  className,
  materials,
  onFavouriteClick,
}) => {
  const materialsLength = materials?.length
  const galleryModulePrfx = 'gallery-component'
  const galleryModuleCls = classNames(galleryModulePrfx, {
    [`gallery-grid_single`]: materialsLength === 1,
    [`${className}`]: className,
  })

  // const { mutateAsync: favouriteMaterial } = useFavouriteItem(queryKey)

  // const handleFavouriteClick = (id: number) => {
  //   favouriteMaterial(id)
  // }

  return (
    <div className={`gallery-grid ${galleryModuleCls}`}>
      {materials.map((material) => (
        <MaterialImg
          className={`gallery-grid__item ${galleryModulePrfx}__img`}
          srcPath={getProtectedPathFromHash(material.protectedHash)}
          key={material.id}
          StatComponent={
            <ImgStatWrapper
              date={material.uploadDate}
              contributor={material.contributor}
              category={material.category}
              pathTo={`${MATERIALS_PATH}/${material.id}`}
              // blank={true}
              onFavouriteClick={() => onFavouriteClick(material.id)}
              isFavourite={material?.isFavorite}
            />
          }
        />
      ))}
    </div>
  )
}
