import React from 'react'
import { useParams } from 'react-router'
import { useMaterialById } from '../../pages/DetailsPage/DetailsPage-hooks'
import { MaterialDetailType } from '../../utils/types'
import { getProtectedPathFromHash } from '../../utils/utils'
import { MaterialImg } from '../MaterialImg'
import { Popup } from '../Popup'
import './ImgFullScreen.sass'

export const ImgFullScreen = () => {
  const { id } = useParams<'id'>()
  const { data: materialDetails } = useMaterialById(+id!) as {
    data: MaterialDetailType
  }
  const path = getProtectedPathFromHash(materialDetails?.protectedHash)
  return (
    <Popup>
      <div className={'fullscreen-container'}>
        <MaterialImg srcPath={path} contain />
      </div>
    </Popup>
  )
}
