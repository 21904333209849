import { PURCHASED_API_PATH } from '../../../../constants/globalConstants'
import queryString from 'query-string'
import { instance } from '../../../../utils/coreAPI'
import { GALLERY_SIZE } from './GalleryModule-constants'

export const getPurchasedMaterials = async (
  page = 1,
  category: string | undefined,
  filterDate: string | null,
  sortOrder: string | undefined,
  filterAuthor: string | null
) => {
  const config = {
    params: {
      page: page,
      size: GALLERY_SIZE,
      category: category ? category : undefined,
      interval: filterDate ? filterDate : undefined,
      sort: sortOrder ? sortOrder : 'desc.add',
      contributorId: filterAuthor ? filterAuthor : undefined,
    },
    paramsSerializer: (params: any) => {
      return queryString.stringify(params)
    },
  }
  try {
    const response = await instance.get(PURCHASED_API_PATH, config)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
