import { instance } from '../utils/coreAPI'

export const tagSearchCall = async (value: string) => {
  try {
    if (value.length > 1) {
      const response = await instance.get(`/prompt/tags?tag=${value}`)
      return response.data
    } else {
      return []
    }
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
