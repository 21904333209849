import classNames from 'classnames'
import React from 'react'
import { ExclusiveRightsComponent } from '../../../../components/ExclusiveRightsComponent'
import { MaterialDetailAuthor } from '../../../../components/MaterialDetailAuthor'
import { MaterialDetailCategory } from '../../../../components/MaterialDetailCategory'
import { MaterialDetailDescription } from '../../../../components/MaterialDetailDescription'
import { MaterialDetailItem } from '../../../../components/MaterialDetailItem'
import { MaterialTagsItem } from '../../../../components/MaterialTagsItem'
import { MaterialDetailType } from '../../../../utils/types'
import { getNormalizeDate } from '../../../../utils/utils'
import './MaterialInfoModule.sass'

interface MaterialInfoModuleProps {
  className?: string
  info: MaterialDetailType
}

export const MaterialInfoModule: React.FC<MaterialInfoModuleProps> = ({
  className,
  info,
}) => {
  const infoPrfx = 'material-info'
  const infoCls = classNames(infoPrfx, {
    [`${className}`]: className,
  })

  const publishedDateValue = getNormalizeDate(info?.uploadDate) ?? 'Неизвестно'
  const descriptionValue = info?.description ?? 'Без описания'
  const tagsValue = info?.tags ?? []

  return (
    <div className={infoCls}>
      {info.contributor?.id && (
        <MaterialDetailAuthor
          contributor={info.contributor}
          className={`${infoPrfx}__item`}
        />
      )}
      {info.category?.id && (
        <MaterialDetailCategory
          category={info.category}
          className={`${infoPrfx}__item`}
        />
      )}
      {publishedDateValue && (
        <MaterialDetailItem
          data={publishedDateValue}
          className={`${infoPrfx}__item`}
        />
      )}
      {descriptionValue && (
        <MaterialDetailDescription
          description={descriptionValue}
          className={`${infoPrfx}__item`}
        />
      )}
      {tagsValue.length && (
        <MaterialTagsItem
          tags={tagsValue}
          className={`${infoPrfx}__item ${infoPrfx}__item_tags`}
        />
      )}
      {info?.exclusiveRights && (
        <ExclusiveRightsComponent className={`${infoPrfx}__item`} />
      )}
    </div>
  )
}
