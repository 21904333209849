import { AUTH_DATA_RESET, AUTH_DATA_SET } from './AuthForm-constants'
import { AuthActions, AuthStateParams } from './AuthForm-types'

export const authDataSetAction = (authData: AuthStateParams): AuthActions => {
  return {
    type: AUTH_DATA_SET,
    payload: authData,
  }
}

export const authDataResetAction = (): AuthActions => {
  return {
    type: AUTH_DATA_RESET,
  }
}
