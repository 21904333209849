import classNames from 'classnames'
import React from 'react'
import { SkeletonBase } from '../SkeletonBase'
import './SkeletonMaterialDetail.sass'

interface SkeletonMaterialDetailProps {
  className?: string
}

export const SkeletonMaterialDetail: React.FC<SkeletonMaterialDetailProps> = ({
  className,
}) => {
  const divPrfx = 'skeleton-material-detail'
  const divCls = classNames(divPrfx, {
    [`${className}`]: className,
  })
  return (
    <div className={divCls}>
      <SkeletonBase type='text' className={`${divPrfx}__title`} />
      <SkeletonBase type='text' className={`${divPrfx}__value`} />
    </div>
  )
}
