import { AUTHORS_API_PATH } from '../../constants/globalConstants'
import { instance } from '../../utils/coreAPI'
import { AUTHORS_COUNT } from './AuthorsPage-constants'

export const getPopularAuthors = async (page: number = 0) => {
  try {
    const response = await instance.get(`${AUTHORS_API_PATH}`, {
      params: {
        page,
        size: AUTHORS_COUNT,
      },
    })
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
