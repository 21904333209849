import { ROLE_CONSUMER } from '../constants/roles'

export const isAuthenticated = () => {
  const permissions = localStorage.getItem('permissions')
  const token = localStorage.getItem('token')
  if (!permissions && !token) {
    return false
  }
  const isAuth = permissions === ROLE_CONSUMER
  if (isAuth) {
    return true
  } else {
    return false
  }
}

export const isUser = () => {
  const permissions = localStorage.getItem('permissions')
  if (!permissions) {
    return false
  }
  return permissions === ROLE_CONSUMER ? true : false
}
