import React, { RefObject } from 'react'
import { useDispatch } from 'react-redux'
import { breadCrumbsSet } from '../modules/BreadCrumbs/BreadCrumbs-actions'
import { breadcrumbsType } from '../modules/BreadCrumbs/BreadCrumbs-types'

type AnyEvent = MouseEvent | TouchEvent

export const useBreadcrumbs = (breadcrumbs: breadcrumbsType) => {
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(breadCrumbsSet(breadcrumbs))
  }, [dispatch, breadcrumbs])
}

export const useHorizontalScroll = () => {
  const elRef = React.useRef<HTMLUListElement>(null)
  React.useEffect(() => {
    const el = elRef?.current
    if (el) {
      const onWheel = (e: WheelEvent) => {
        if (e.deltaY === 0) return
        e.preventDefault()
        el.scrollTo({
          left: el.scrollLeft + e.deltaY,
          // behavior: 'smooth',
        })
      }
      // TODO: Посмотреть на passive: true
      // el.addEventListener('wheel', onWheel, { passive: true })
      el.addEventListener('wheel', onWheel)
      return () => el.removeEventListener('wheel', onWheel)
    }
  }, [])
  return elRef
}

export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: AnyEvent) => void
): void {
  React.useEffect(() => {
    const listener = (event: AnyEvent) => {
      const el = ref?.current

      // Do nothing if clicking ref's element or descendent elements
      if (!el || el.contains(event.target as Node)) {
        return
      }

      handler(event)
    }

    document.getElementById('root')!.addEventListener(`mousedown`, listener)
    document.getElementById('root')!.addEventListener(`touchstart`, listener)

    return () => {
      document
        .getElementById('root')!
        .removeEventListener(`mousedown`, listener)
      document
        .getElementById('root')!
        .removeEventListener(`touchstart`, listener)
    }

    // Reload only if ref or handler changes
  }, [ref, handler])
}
