import classNames from 'classnames'
import React from 'react'
import { useSelector } from 'react-redux'
import { EmptyResult } from '../../../../components/EmptyResult'
import { NewsComponent } from '../../../../components/NewsComponent'
import { SkeletonNews } from '../../../../components/SkeletonNews'
import { MATERIALS_PATH } from '../../../../constants/globalConstants'
import { useFavouriteNewsItem, useFetchNews } from './NewsModule-hooks'
import './NewsModule.sass'
import { MaterialImg } from '../../../../components/MaterialImg'
import { ImgStatWrapper } from '../../../../components/ImgStatWrapper'
import { getProtectedPathFromHash } from '../../../../utils/utils'
import { NewsResponseType } from './NewsModule-types'
import { MaterialPreviewType } from '../../../../utils/types'
import { RootState } from '../../../../reducers'

interface NewsModuleProps {
  className?: string
}

export const NewsModule: React.FC<NewsModuleProps> = ({ className }) => {
  const newsWrapperPrfx = 'news-wrapper'
  const newsPrfx = 'news'
  const newsWrapperCls = classNames(newsWrapperPrfx, {
    [`${className}`]: className,
  })

  const isAuth = useSelector((state: RootState) => state.auth.isAuth)

  const {
    data: newsResponseData,
    isLoading,
    isError,
  } = useFetchNews(isAuth) as {
    data: NewsResponseType
    isLoading: boolean
    isError: boolean
  }

  const { mutateAsync: favouriteMaterial } = useFavouriteNewsItem(isAuth)
  const handleFavouriteClick = (id: number) => {
    favouriteMaterial(id)
  }

  const materialsLength = newsResponseData?.materials?.length
  const newsLength = newsResponseData?.news?.length

  if (isLoading) {
    return <SkeletonNews />
  }
  if (isError) {
    return null
  }
  if (!newsLength) {
    return <EmptyResult />
  }

  return (
    <div className={newsWrapperCls}>
      <div className={`gallery-grid ${newsPrfx}`}>
        <NewsComponent
          news={newsResponseData.news}
          className={`${newsPrfx}__news`}
        />
        {materialsLength
          ? newsResponseData.materials
              .slice(0, 8)
              .map((material: MaterialPreviewType) => (
                <MaterialImg
                  className={`gallery-grid__item ${newsPrfx}__img`}
                  srcPath={getProtectedPathFromHash(material.protectedHash)}
                  key={material.id}
                  StatComponent={
                    <ImgStatWrapper
                      date={material.uploadDate}
                      contributor={material.contributor}
                      category={material.category}
                      pathTo={`${MATERIALS_PATH}/${material.id}`}
                      onFavouriteClick={() => handleFavouriteClick(material.id)}
                      isFavourite={material?.isFavorite}
                    />
                  }
                />
              ))
          : null}
      </div>
    </div>
  )
}
