import classNames from 'classnames'
import React from 'react'
import queryString from 'query-string'
import { EmptyResult } from '../../../../components/EmptyResult'
import { Gallery } from '../../../../modules/Gallery'
import { IncorretResult } from '../../../../components/IncorretResult'
import { Pagination } from '../../../../components/Pagination'
import { SkeletonGallery } from '../../../../components/SkeletonGallery'
import { MATERIAL_CACHE_PATH } from '../../../../constants/globalConstants'
import { MaterialsResponseType } from '../../../../utils/types'
import { GALLERY_SIZE } from './GalleryModule-constants'
import {
  useFavouriteItem,
  useFetchFavouriteMaterials,
} from './GalleryModule-hooks'
import { useNavigate } from 'react-router'
import { useURLParams } from '../../../../hooks/useURLParams'
import {
  CATEGORY_URL_VALUE,
  CONTRIBUTOR_URL_VALUE,
  INTERVAL_URL_VALUE,
  PAGE_URL_VALUE,
  SORT_URL_VALUE,
} from '../../../../constants/filterURLNames'

interface GalleryModuleProps {
  className?: string
}

export const GalleryModule: React.FC<GalleryModuleProps> = ({ className }) => {
  const navigate = useNavigate()
  const params = useURLParams()

  const category = params[CATEGORY_URL_VALUE]
  const sortOrder = params[SORT_URL_VALUE]
  const contributorId = params[CONTRIBUTOR_URL_VALUE]
  const filterDate = params[INTERVAL_URL_VALUE]
  const page = params[PAGE_URL_VALUE]

  const currentPage = page ? +page : 1

  const queryKey = [
    `${MATERIAL_CACHE_PATH}`,
    {
      type: 'favourite',
      page: currentPage,
      category,
      filterDate,
      sortOrder,
      contributorId,
    },
  ]

  const {
    data: materialsRequestData,
    isLoading,
    isFetching,
    isError,
  } = useFetchFavouriteMaterials(
    currentPage,
    category,
    filterDate,
    sortOrder,
    contributorId
  ) as {
    data: MaterialsResponseType
    isLoading: boolean
    isFetching: boolean
    isError: boolean
  }

  const { mutateAsync: favouriteMaterial } = useFavouriteItem(queryKey)

  const handleFavouriteClick = (id: number) => {
    favouriteMaterial(id)
  }

  const materials = React.useMemo(
    () => materialsRequestData?.items,
    [materialsRequestData]
  )
  const total = materialsRequestData?.total

  const pageCount = React.useMemo(() => {
    return Math.ceil(total / GALLERY_SIZE)
  }, [total])

  const handleChangePage = (page: number) => {
    params[PAGE_URL_VALUE] = (page + 1).toString()
    navigate({ search: queryString.stringify(params) })
  }

  const galleryPrfx = 'main-gallery'
  const galleryCls = classNames(galleryPrfx, {
    [`${galleryPrfx}_fetching`]: isFetching === true,
    [`${className}`]: className,
  })

  if (isLoading) {
    return <SkeletonGallery itemsCount={GALLERY_SIZE} />
  }
  if (isError) {
    return <IncorretResult />
  }
  if (materials?.length === 0) {
    return <EmptyResult />
  }

  return (
    <div className={galleryCls}>
      <Gallery materials={materials} onFavouriteClick={handleFavouriteClick} />
      {pageCount > 1 && (
        <Pagination
          className={`${galleryPrfx}__pagination`}
          activeId={currentPage - 1}
          onChange={handleChangePage}
          count={pageCount}
        />
      )}
    </div>
  )
}
